import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import authContext from "../../contexts/authContext";

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();
  const { setAsideStatus, setOpenMenuMobile } = useContext(authContext);
  useEffect(() => {
    // Tìm phần tử với class cụ thể
    const targetElement = document.querySelector(".body");

    // Nếu phần tử được tìm thấy, thực hiện cuộc cuộn đến vị trí của nó
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth", // Cuộn mềm mại
      });
    }
  }, [pathname]); // [] đảm bảo sự kiện chỉ được đăng ký một lần khi component mount
  useEffect(() => {
    setAsideStatus(false);
    setOpenMenuMobile(false);
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};

export default ScrollToTop;
