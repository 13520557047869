import React from "react";
import styles from "./phaply.module.css";
import phaply1 from "../../../assets/img/phap-ly/phap-ly-1.webp";
import phaply2 from "../../../assets/img/phap-ly/phap-ly-2.webp";
import phaply3 from "../../../assets/img/phap-ly/phap-ly-3.webp";
import phaply4 from "../../../assets/img/degree.webp";
const Phaply = () => {
  return (
    <div className={styles.phapLy}>
      <div className={styles.phapLy__header}>
        <div className={styles.phapLy__layer}></div>
        <h2>Pháp Lý V/V Cung Cấp Giấy Tờ Và Chứng Từ Hàng Hóa</h2>
      </div>
      <div className={styles.phapLy__body}>
        <img src={phaply1} alt="" />
        <img src={phaply3} alt="" />
        <img src={phaply2} alt="" />
        <img src={phaply4} alt="" />

        {/* <div>
          <h4>
            Công ty Cổ Phần Tập Đoàn Khỏe Đẹp Hạnh Phúc sẽ cung cấp kèm theo
            những giấy tờ và chứng từ sau trong quá trình giao nhận hàng hóa tới
            khách hàng:
          </h4>
          <ul>
            <li>
              Hợp đồng mua bán hàng hóa (áp dụng với những đơn hàng SLL), ghi
              lại toàn bộ các thỏa thuận, quyền, nghĩa vụ của hai bên mua và
              bán.
            </li>
            <li>
              Hóa đơn hàng hóa, đóng vai trò là cơ sở cho quá trình thanh toán.
            </li>
            <li>
              Phiếu niêm phong thùng hàng bao gồm các thông tin của người mua và
              bán, thông tin kiện hàng (tên hàng hóa, số lượng hàng hóa, trọng
              lượng hàng hóa...).
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default Phaply;
