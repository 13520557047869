import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import classNames from "classnames";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { FaSignOutAlt, FaUser } from "react-icons/fa";
import {
  IoIosNotifications,
  IoMdArrowDropdown,
  IoMdMenu,
} from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useMedia } from "react-use";
import { getInfo } from "../../../api/authApi";
import avatarImg from "../../../assets/img/default-user-image.svg";
import authContext from "../../../contexts/authContext";
import "../../../styles/header.css";
import {
  getUserNotification,
  readUserNotification,
} from "../../../api/notificationApi";

const CustomHeader = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notiEl, setNotiEl] = useState(null);
  const { userData, setUserData, asideStatus, setAsideStatus, noti, setNoti } =
    useContext(authContext);
  const [notiHeader, setNotiHeader] = useState(noti);
  const open = Boolean(anchorEl);
  const openNoti = Boolean(notiEl);
  const isWide = useMedia("(min-width: 769px)");
  const isMobile = useMedia("(min-width: 425px)");
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickNoti = (event) => {
    setNotiEl(event.currentTarget);
  };
  const handleCloseNoti = () => {
    setNotiEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = async () => {
    localStorage.removeItem("access_token");
    navigate("/dang-nhap");
  };
  const handleGetInfo = async () => {
    try {
      const res = await getInfo();
      if (res) {
        setUserData(res.data);
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("access_token");
        navigate("/dang-nhap");
      }

      // console.log(err);
    }
  };
  useEffect(() => {
    handleGetInfo();
  }, []);
  const handleExpiredDate = () => {
    if (!userData?.expired_date) {
      return (
        <div
          className="text-muted"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/mua-hang");
          }}
        >
          Bạn chưa mua gói
        </div>
      );
    } else {
      if (tinhSoNgayConLai(userData?.expired_date) > 0) {
        return (
          <div className="text-success">
            <span
              className={classNames({
                "d-none": !isWide,
              })}
            >
              Ngày hết hạn:{" "}
            </span>
            <span className="fw-bold ms-2">
              {moment(userData?.expired_date).format("HH:mm DD-MM-YYYY")}
            </span>
          </div>
        );
      } else {
        return (
          <div>
            <span className="fw-bold text-danger">Gói đã hết hạn</span>
          </div>
        );
      }
    }
  };
  function tinhSoNgayConLai(date) {
    var ngayHienTai = new Date();

    var ngayMongMuonObject = new Date(date);

    var soMillisecondsConLai = ngayMongMuonObject - ngayHienTai;

    var soNgayConLai = Math.ceil(soMillisecondsConLai / (1000 * 60 * 60 * 24));
    return soNgayConLai;
  }
  const markNoti = async (id) => {
    try {
      const res = await readUserNotification({ id: id });
      if (res.success) {
        handleGetNoti();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleGetNoti = async () => {
    try {
      const res = await getUserNotification();
      if (res.success) {
        setNoti(res?.data);
        setNotiHeader(res?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    handleGetNoti();
  }, []);
  return (
    <div className="header__custom">
      <div
        className={classNames(
          "d-flex justify-content-between w-100 flex-row align-items-center"
        )}
      >
        {isWide ? (
          <div className="d-flex">
            {handleExpiredDate()}
            <div className="mx-2 text-success">-</div>
            <div className="text-success">
              <span
                className={classNames({
                  "d-none": !isWide,
                })}
              >
                Cấp bậc:{" "}
              </span>
              <span className="fw-bold ms-2">
                {userData?.rank === 0
                  ? "Nhà phân phối"
                  : userData?.rank === 1
                  ? "Trưởng phòng kinh doanh"
                  : userData?.rank === 2
                  ? "Giám đốc kinh doanh"
                  : "Cộng tác viên"}
              </span>
            </div>
          </div>
        ) : (
          <>
            {isMobile ? (
              <div className="d-flex align-items-center">
                <IoMdMenu
                  onClick={() => {
                    setAsideStatus(true);
                  }}
                  style={{ cursor: "pointer", fontSize: "24px" }}
                />
                <div className="d-flex ms-2">
                  {handleExpiredDate()}
                  <div className="mx-2 text-success">-</div>
                  <div className="text-success">
                    <span
                      className={classNames({
                        "d-none": !isWide,
                      })}
                    >
                      Cấp bậc:{" "}
                    </span>
                    <span className="fw-bold ms-2">
                      {userData?.rank === 0
                        ? "Nhà phân phối"
                        : userData?.rank === 1
                        ? "Trưởng phòng kinh doanh"
                        : userData?.rank === 2
                        ? "Giám đốc kinh doanh"
                        : "Cộng tác viên"}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <IoMdMenu
                onClick={() => {
                  setAsideStatus(true);
                }}
                style={{ cursor: "pointer", fontSize: "24px" }}
              />
            )}
          </>
        )}
        <div className="d-flex align-items-stretch">
          <div
            className="d-flex justify-content-center align-items-center"
            aria-controls={openNoti ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openNoti ? "true" : undefined}
          >
            <Badge
              className="me-2"
              color="success"
              style={{ cursor: "pointer" }}
              badgeContent={
                notiHeader?.filter((item) => item.is_read === 0).length
              }
              onClick={handleClickNoti}
            >
              <IoIosNotifications style={{ fontSize: "30px" }} />
            </Badge>
          </div>
          <Menu
            className="mt-3"
            id="noti-menu"
            anchorEl={notiEl}
            open={openNoti}
            onClose={handleCloseNoti}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem style={{background: '#fff'}} className="p-2 ps-3  border-bottom fw-bold">
              Thông báo
            </MenuItem>
            {notiHeader?.length !== 0 ? (
              <>
                {notiHeader?.map((item) => (
                  <MenuItem
                    onClick={() => {
                      markNoti(item?.id);
                    }}
                    className="border-bottom py-2 px-4 w-100"
                    key={item?.id}
                  >
                    <div className="d-flex align-items-center w-100">
                      <img
                        src={avatarImg}
                        style={{
                          width: "45px",
                          height: "45px",
                          borderRadius: "50%",
                        }}
                        alt=""
                      />
                      <div
                        className={`ms-2 w-100 ${
                          item.is_read === 0 ? "fw-bold" : "fw-normal"
                        }`}
                      >
                        <div
                          className="text-truncate d-flex justify-content-between align-items-center"
                          style={{ fontSize: "16px" }}
                        >
                          Admin 
                          <span
                            className="text-muted"
                            style={{ fontSize: "12px" }}
                          >
                            {moment(item?.created_at).fromNow()}
                          </span>
                        </div>
                        <div
                          style={{ fontSize: "14px" }}
                          className=" text-truncate"
                        >
                          {item?.message}
                        </div>
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </>
            ) : (
              <>
                <MenuItem className="d-flex justify-content-center pt-3 border-top ">
                  Hiện tại không có thông báo mới nào.
                </MenuItem>
              </>
            )}
          </Menu>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <div
              style={{ cursor: "pointer" }}
              className="d-flex align-items-center"
            >
              <img
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                src={userData?.avatar ? userData?.avatar : avatarImg}
                alt=""
              />
              <div className="ms-2 fw-bold text-dark">
                <span
                  className={classNames({
                    "d-none": !isWide,
                  })}
                >
                  {userData?.name ? userData?.name : "Username"}
                </span>
                <IoMdArrowDropdown />
              </div>
            </div>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                if (userData?.role_id == 1) {
                  navigate("/quan-tri/thong-tin-ca-nhan");
                } else {
                  navigate("/he-thong/thong-tin-ca-nhan");
                }
              }}
            >
              <FaUser className="me-2" />
              Thông tin cá nhân
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <FaSignOutAlt className="me-2" /> Đăng xuất
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default CustomHeader;
