import { FaCartShopping } from "react-icons/fa6";
import { GrTransaction } from "react-icons/gr";
import { IoSettings } from "react-icons/io5";
import { MdAccountCircle, MdSpaceDashboard } from "react-icons/md";
import { BiMoneyWithdraw } from "react-icons/bi";
import { CiBank } from "react-icons/ci";
import { TbTransfer } from "react-icons/tb";
import { FaHistory, FaHome } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { FaUserFriends } from "react-icons/fa";
import { FaFolderTree } from "react-icons/fa6";
import { IoIosSettings, IoIosPersonAdd } from "react-icons/io";

export const summaryPageTopMenu = {};
export const adminMenu = {
  home: {
    id: "home",
    text: "Trang chủ",
    path: "/",
    icon: <FaHome />,
    // icon: "Login",
  },
  dashboard: {
    id: "dasboard",
    text: "Tổng quan",
    path: "/*",
    icon: <MdDashboard />,
    // icon: "Login",
  },
  adminTransactions: {
    id: "adminTransactions",
    text: "Quản lý giao dịch",
    path: "/quan-tri/quan-ly-giao-dich",
    icon: <GrTransaction />,
    subMenu: {
      adminWithdraw: {
        id: "adminWithdraw",
        text: "Rút tiền",
        path: "/quan-tri/rut-tien",
        icon: <BiMoneyWithdraw />,
      },
      adminRecharge: {
        id: "adminRecharge",
        text: "Nạp tiền",
        path: "/quan-tri/nap-tien",
        icon: <CiBank />,
      },
      adminTransfer: {
        id: "adminTransfer",
        text: "Chuyển tiền",
        path: "/quan-tri/chuyen-tien",
        icon: <TbTransfer />,
      },
      adminTransactions: {
        id: "adminTransactions",
        text: "Lịch sử giao dịch",
        path: "/quan-tri/lich-su-giao-dich",
        icon: <GrTransaction />,
      },
    },
  },
  adminOrders: {
    id: "adminOrders",
    text: "Quản lý đơn hàng",
    path: "/quan-tri/quan-ly-don-hang",
    icon: <MdOutlineProductionQuantityLimits />,
  },
  allusers: {
    id: "allusers",
    text: "Quản lý người dùng",
    path: "/quan-tri/quan-ly-nguoi-dung",
    icon: <FaUserFriends />,
  },
  usersFund: {
    id: "usersFund",
    text: "Quản lý Quỹ",
    path: "/quan-tri/quan-ly-quy",
    icon: <FaUserFriends />,
  },
  registerEmail: {
    id: "allusers",
    text: "Người đăng ký mới",
    path: "/nguoi-dang-ky-moi",
    icon: <IoIosPersonAdd />,
  },
  admintree: {
    id: "admintree",
    text: "sơ đồ hệ thống",
    path: "/quan-tri/so-do-quan-tri-nguoi-dung",
    icon: <FaFolderTree />,
  },
  accountsettings: {
    id: "accountsettings",
    text: "thông tin cá nhân",
    path: "/quan-tri/thong-tin-ca-nhan",
    icon: <IoIosSettings />,
  },
};
export const adminDashboardMenu = {
  home: {
    id: "home",
    text: "Trang chủ",
    path: "/",
    icon: <FaHome />,
    // icon: "Login",
  },
  dashboard: {
    id: "dasboard",
    text: "Tổng quan",
    path: "/*",
    icon: <MdSpaceDashboard />,
    // icon: "Login",
  },
  order: {
    id: "order",
    text: "Đơn hàng của tôi",
    path: "/he-thong/don-hang-cua-toi",
    icon: <FaCartShopping />,
    // icon: "Login",
  },
  transactions: {
    id: "transactions",
    text: "Giao dịch",
    path: "/he-thong/quan-ly-giao-dich",
    icon: <GrTransaction />,
    subMenu: {
      recharge: {
        id: "recharge",
        text: "Nạp tiền",
        path: "/he-thong/nap-tien",
        icon: <CiBank />,
      },
      gift: {
        id: "gift",
        text: "Chuyển tiền",
        path: "/he-thong/chuyen-tien",
        icon: <TbTransfer />,
      },
      withdraw: {
        id: "withdraw",
        text: "Rút tiền",
        path: "/he-thong/rut-tien",
        icon: <BiMoneyWithdraw />,
      },
      transactions: {
        id: "transactions",
        text: "Lịch sử giao dịch",
        path: "/he-thong/lich-su-giao-dich",
        icon: <FaHistory />,
      },
    },
  },
  users: {
    id: "users",
    text: "Hệ thống của tôi",
    path: "/he-thong/nguoi-dung",
    icon: <IoSettings />,
    subMenu: {
      systemtree: {
        id: "systemtree",
        text: "Sơ đồ hệ thống",
        path: "/he-thong/so-do-he-thong-nguoi-dung",
        icon: <FaFolderTree />,
      },
      allmems: {
        id: "allmems",
        text: "Tất cả thành viên",
        path: "/he-thong/tat-ca-thanh-vien",
        icon: <FaUserFriends />,
      },
    },
  },
  accountsettings: {
    id: "accountsettings",
    text: "thông tin cá nhân",
    path: "/he-thong/thong-tin-ca-nhan",
    icon: <MdAccountCircle />,
  },
};
export const dashboardPagesMenu = {
  dashboard: {
    id: "dasboard",
    path: "/*",
  },
};

export const demoPagesMenu = {
  pages: {
    id: "pages",
    text: "Pages",
    icon: "Extension",
  },
  auth: {
    id: "auth",
    text: "Auth Pages",
    icon: "Extension",
  },
  loginAdmin: {
    id: "loginAdmin",
    text: "Login Admin",
    path: "dang-nhap-admin",
    icon: "Login",
  },
  login: {
    id: "login",
    text: "Login",
    path: "dang-nhap",
    icon: "Login",
  },
  register: {
    id: "register",
    text: "Register",
    path: "dang-ky",
    icon: "Register",
  },
  forgotPassword: {
    id: "forgotPassword",
    text: "Forgot Password",
    path: "quen-mat-khau",
    icon: "ForgotPassword",
  },
  landingPage: {
    id: "landing",
    text: "landing",
    path: "",
    icon: "Landing",
  },
  aboutUs: {
    id: "aboutUs",
    text: "About us",
    path: "/ve-chung-toi",
    icon: "AboutUs",
  },
  order: {
    id: "order",
    text: "Order",
    path: "/mua-hang",
    icon: "Order",
  },
  phapLy: {
    id: "juridical",
    text: "Juridical",
    path: "/phap-ly",
    icon: "Juridical",
  },
  chinhSach: {
    id: "chinhSach",
    text: "chinhSach",
    path: "/chinh-sach",
    icon: "ChinhSach",
  },
  daily: {
    id: "daily",
    text: "daily",
    path: "/dai-ly",
    icon: "DaiLy",
  },
};
