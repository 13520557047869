import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getInfo } from "../../../api/authApi";
import { sendPost } from "../../../api/axiosClients";
import botdinhduong from "../../../assets/img/landing-page/product3.png";
import authContext from "../../../contexts/authContext";
import { useNotification } from "../../../contexts/notificationContext";
import JsonFile from "../../../data/Index.json";
import styles from "./order.module.css";
import { numberFormat, priceFormat } from "../../../helpers/helpers";
const Order = () => {
  const [province, setProvince] = React.useState("");
  const [district, setDistrict] = React.useState("");
  const [ward, setWard] = React.useState("");
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [provinceSettings, setProvinceSettings] = useState([]);
  const [wardOptions, setWardOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    depot: "",
    method: "",
  });
  const { showNoti } = useNotification();
  const { userData, setUserData } = useContext(authContext);
  const navigate = useNavigate();
  const [orderStatus, setOrderStatus] = useState(false);
  const formik = useFormik({
    initialValues: {
      quantity: localStorage.getItem("orderQuantity")
        ? localStorage.getItem("orderQuantity")
        : 1,
    },
  });
  const [checkError, setCheckError] = useState({
    phone: "",
    full_name: "",
    email: "",
    address: "",
    ward: "",
    province: "",
    district: "",
    note: "",
    depot: "",
    method: "",
    quantity: "",
  });

  const handleChangeProvince = async (event) => {
    const res = (await import(`../../../data/data/${event.target.value}.json`))
      .default;
    // const data = await res.json();
    const mapData = res?.district?.map((item) => {
      return {
        name: item?.name,
        value: item?.name,
      };
    });
    setProvinceSettings(res.district);
    setDistrictOptions(mapData);
    // setDistrict(mapData[0]?.value);
    // setWardOptions(res.district[0]?.ward);
    // setWard(res.district[0]?.ward[0]?.name);
    setDistrict('')
    setWardOptions([])
    setProvince(event.target.value);
  };

  const handleChangeDistrict = (event) => {
    const filterProvince = provinceSettings.filter(
      (item) => item.name == event.target.value
    );
    if (filterProvince) {
      setWardOptions(filterProvince[0]?.ward);
    }
    setWard('')
    // setWard(filterProvince[0]?.ward[0]?.name);
    // console.log(filterProvince[0]?.ward[0]?.name);
    setDistrict(event.target.value);
  };

  const handleChangeWard = (event) => {
    setWard(event.target.value);
  };

  useEffect(() => {
    const convert = Object.keys(JsonFile).map((item) => {
      return {
        value: JsonFile[item].code,
        name: item,
      };
    });
    setProvinceOptions(convert);
  }, []);
  const handleOrder = async () => {
    if (userData)
      if (
        formData?.name === "" ||
        formData?.email === "" ||
        formData?.phone === "" ||
        formData?.address === "" ||
        formData?.depot === "" ||
        formData?.method === "" ||
        ward === "" ||
        district === "" ||
        province === ""
      ) {
        setCheckError({
          phone: formData?.phone === "" ? "Số điện thoại là bắt buộc." : "",
          full_name: formData?.name === "" ? "Họ tên là bắt buộc." : "",
          email: formData?.email === "" ? "Địa chỉ email là bắt buộc." : "",
          ward: ward === "" ? "Phường/xã là bắt buộc" : "",
          province: province === "" ? "Tỉnh / thành là bắt buộc" : "",
          district: district === "" ? "Quận / huyện là bắt buộc" : "",
          address: formData?.address === "" ? "Địa chỉ là bắt buộc" : "",
          depot: formData?.depot === "" ? "Kho hàng là bắt buộc" : "",
          method:
            formData?.method === "" ? "Phương thức thanh toán là bắt buộc" : "",
        });
        return;
      }
    try {
      const input = {
        full_name: formData?.name,
        email: formData?.email,
        phone: formData?.phone,
        address: formData?.address,
        ward: ward,
        province: province,
        district: district,
        depot: Number(formData?.depot),
        method: Number(formData?.method),
        quantity: formik.values.quantity,
      };
      const res = await sendPost(`/user/order/create`, input);

      // if (res.status === 200) {
      setOrderStatus(true);
      showNoti(
        res?.status_text ? res?.status_text : "Đặt hàng thành công !",
        "success"
      );
      setCheckError({
        phone: "",
        full_name: "",
        email: "",
        address: "",
        ward: "",
        province: "",
        district: "",
        note: "",
      });
      // }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/dang-nhap");
      } else if (error?.response?.status === 422) {
        if (error?.response?.data?.error_msg?.quantity) {
          showNoti(error?.response?.data?.error_msg?.quantity, "error");
        } else {
          setCheckError((old) => ({
            phone: "",
            full_name: "",
            email: "",
            address: "",
            ...error?.response?.data?.error_msg,
          }));
        }
      } else {
        setCheckError({
          phone: "",
          full_name: "",
          email: "",
          address: "",
          ward: "",
          province: "",
          district: "",
          note: "",
        });
        showNoti(
          error?.response?.data?.error_msg
            ? error?.response?.data?.error_msg
            : error?.response?.data?.message,
          "error"
        );
      }
    }
  };

  const getUserInfo = async () => {
    try {
      const res = await getInfo();
      if (res) {
        setUserData(res.data);
      }
    } catch (err) {
      localStorage.removeItem("access_token");
      navigate("/dang-nhap");
      // console.log(err);
    }
  };

  useEffect(() => {
    if (Object.keys(userData).length === 0 && userData.constructor === Object) {
      getUserInfo();
    } else {
      setDistrict(userData?.info?.district ? userData?.info?.district : "");
      setFormData({
        ...formData,
        address: userData?.info?.address ? userData?.info?.address : "",
        name: userData?.name ? userData?.name : "",
        phone: userData?.phone ? userData?.phone : "",
        email: userData?.email ? userData?.email : "",
      });
    }
  }, [userData]);

  const handleErrorClass = (id) => {
    if (checkError[id] !== "") {
      return `${styles.orderInput} ${styles.orderError}`;
    } else {
      return styles.orderInput;
    }
  };

  const handleErrorMessage = (id) => {
    if (checkError[id] !== "") {
      return <p className={styles.errorMessage}>{checkError[id]}</p>;
    }
  };
  return (
    <div className={styles.order}>
      <div className={styles.aboutUs__section1}>
        <div className={styles.section1__layer}></div>
        <div className={styles.section1__content}>
          <h1>Thanh toán</h1>
        </div>
      </div>
      {orderStatus ? (
        <div className={styles.orderSuccessBody}>
          <div className="text-center">
            <h2>Đặt hàng thành công !</h2>
            <h6>
              Cảm ơn bạn đã mua hàng, đơn hàng sẽ được vận chuyển đến bạn trong
              thời gian sớm nhất
            </h6>
          </div>
          <div className="d-flex mt-5 justify-content-center">
            <button
              className={styles.orderButton}
              onClick={() => setOrderStatus(false)}
              style={{ width: "200px" }}
            >
              Tiếp tục
            </button>
          </div>
        </div>
      ) : (
        <div className={styles.orderBody}>
          <div className={styles.userCash}>
            <div className="d-flex justify-content-between align-items-center">
              <div className={styles.userCashData}>
                <p className="fw-bold fs-5">{numberFormat(userData?.money)}</p>
                <p>
                  <small>Số dư</small>
                </p>
              </div>
              <div className={styles.userCashIcon}>VNĐ</div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className={styles.userCashData}>
                <p className="fw-bold fs-5">{numberFormat(userData?.coin)}</p>
                <p>
                  <small>Ví khả dụng</small>
                </p>
              </div>
              <div className={styles.userCashIcon}>KTV</div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className={styles.userCashData}>
                <p className="fw-bold fs-5">{numberFormat(userData?.dcoin)}</p>
                <p>
                  <small>Ví tích luỹ</small>
                </p>
              </div>
              <div className={styles.userCashIcon}>KTV</div>
            </div>
          </div>
          <div className={styles.tablePayment}>
            <Table bordered responsive>
              <thead>
                <tr className={styles.orderBody__tableHead}>
                  <th>#</th>
                  <th>Sản phẩm</th>
                  <th>Tên</th>
                  <th>Giá</th>
                  <th>Số lượng</th>
                  <th style={{ width: "300px" }}>Tổng cộng</th>
                </tr>
              </thead>
              <tbody>
                <tr className={styles.orderBody__tableBody}>
                  <td>1</td>
                  <td>
                    <img
                      src={botdinhduong}
                      alt=""
                      className={styles.productImage}
                    />
                  </td>
                  <td>Dinh dưỡng thiên nhiên KTV</td>
                  <td>3.300.000đ</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <button
                      className="cartButton me-2"
                      onClick={() => {
                        if (formik.values.quantity > 1) {
                          formik.setFieldValue(
                            "quantity",
                            --formik.values.quantity
                          );
                        }
                      }}
                    >
                      -
                    </button>
                    <input
                      min={1}
                      value={formik.values.quantity}
                      onChange={(e) => {
                        if (e.target.value < 1) {
                          formik.setFieldValue("quantity", 1);
                        } else if (e.target.value > 100) {
                          formik.setFieldValue("quantity", 100);
                        } else {
                          formik.handleChange(e);
                        }
                      }}
                      id="quantity"
                      className={styles.quantity__input}
                      type="number"
                    />
                    <button
                      className="cartButton ms-2"
                      onClick={() => {
                        if (formik.values.quantity < 100) {
                          formik.setFieldValue(
                            "quantity",
                            ++formik.values.quantity
                          );
                        }
                      }}
                    >
                      +
                    </button>
                  </td>
                  <td>{priceFormat(formik.values.quantity * 3300000)}</td>
                </tr>
                <tr>
                  <td colSpan={5} className="text-end">
                    Thanh toán
                  </td>
                  <td>
                    <p>
                      {priceFormat(formik.values.quantity * 3300000)} {" ~ "}{" "}
                      {(formik.values.quantity * 3300)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                      {"KTV"}
                    </p>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className={styles.orderForm}>
            <h2 className="fw-bold">THÔNG TIN ĐẶT HÀNG!</h2>

            <div>
              <label htmlFor="name">
                Tên người nhận hàng <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="name"
                value={formData?.name}
                className={handleErrorClass("full_name")}
                placeholder="Tên người nhận hàng"
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
              {handleErrorMessage("full_name")}
            </div>
            <div>
              <label htmlFor="email">
                Email <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="email"
                className={handleErrorClass("email")}
                value={formData?.email}
                placeholder="Email"
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
              {handleErrorMessage("email")}
            </div>
            <div>
              <label htmlFor="phone">
                Số điện thoại <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="phone"
                value={formData?.phone}
                placeholder="Số điện thoại"
                className={handleErrorClass("phone")}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
              />
              {handleErrorMessage("phone")}
            </div>
            <div>
              <label htmlFor="province">
                Tỉnh thành <span className="text-danger">*</span>
              </label>
              <br />
              <select
                id="province"
                className={styles.orderInput}
                onChange={handleChangeProvince}
              >
                <option value={""} selected disabled={province !== ""}>
                  Tỉnh thành
                </option>
                {provinceOptions?.map((item, idx) => (
                  <option value={item?.value} key={idx}>
                    {item?.name}
                  </option>
                ))}
              </select>

              {handleErrorMessage("province")}
            </div>
            <div>
              <label htmlFor="district">
                Quận <span className="text-danger">*</span>
              </label>
              <br />
              <select
                id="district"
                value={district}
                disabled={province === "" || !province}
                className={styles.orderInput}
                onChange={handleChangeDistrict}
              >
                <option value={""} selected disabled>
                  Quận/Huyện
                </option>
                {districtOptions?.map((item, idx) => (
                  <option value={item?.value} key={idx}>
                    {item?.name}
                  </option>
                ))}
              </select>

              {handleErrorMessage("district")}
            </div>
            <div>
              <label htmlFor="ward">
                Phường/Xã <span className="text-danger">*</span>
              </label>
              <br />
              <select
                id="ward"
                value={ward}
                disabled={district === "" || !district}
                className={styles.orderInput}
                onChange={handleChangeWard}
              >
                <option value={""} selected disabled>
                  Phường/Xã
                </option>
                {wardOptions?.map((item, idx) => (
                  <option value={item?.name} key={idx}>
                    {item?.name}
                  </option>
                ))}
              </select>

              {handleErrorMessage("ward")}
            </div>
            <div>
              <label htmlFor="address">
                Địa chỉ <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="address"
                value={formData?.address}
                className={handleErrorClass("address")}
                placeholder="Địa chỉ"
                onChange={(e) =>
                  setFormData({ ...formData, address: e.target.value })
                }
              />
              {handleErrorMessage("address")}
            </div>
            <div>
              <label htmlFor="note">Ghi chú</label>
              <textarea
                placeholder="Ghi chú"
                rows={3}
                type="text"
                id="note"
                value={formData?.note}
                className={styles.orderInput}
                onChange={(e) =>
                  setFormData({ ...formData, note: e.target.value })
                }
              />
            </div>
            <div>
              <label htmlFor="depot">
                Kho hàng <span className="text-danger">*</span>
              </label>
              <br />
              <select
                id="depot"
                value={formData.depot}
                className={styles.orderInput}
                onChange={(e) =>
                  setFormData((old) => ({ ...old, depot: e.target.value }))
                }
              >
                <option value={""} selected disabled>
                  Kho hàng
                </option>
                <option value={0}>Hà Nội</option>
                <option value={1}>Hồ Chí Minh</option>
                <option value={2}>Tây Nguyên</option>
              </select>
              {handleErrorMessage("depot")}
            </div>
            <div>
              <label htmlFor="method">
                Phương thức thanh toán <span className="text-danger">*</span>
              </label>
              <br />
              <select
                id="method"
                value={formData?.method}
                className={styles.orderInput}
                onChange={(e) =>
                  setFormData((old) => ({ ...old, method: e.target.value }))
                }
              >
                <option value={""} selected disabled>
                  Phương thức thanh toán
                </option>
                <option value={0}>Thanh toán bằng số dư</option>
                <option value={1}>Thanh toán bằng điểm KTV</option>
                <option value={2}>
                  Thanh toán chung bằng số dư và điểm KTV
                </option>
              </select>
              {handleErrorMessage("method")}
            </div>
            <p>
              Tổng thanh toán: {priceFormat(formik.values.quantity * 3300000)}{" "}
              {" ~ "}{" "}
              {(formik.values.quantity * 3300)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
              {"KTV"}
            </p>
            <div>
              <button className={styles.orderButton} onClick={handleOrder}>
                Đặt hàng
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Order;
