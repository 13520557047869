import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import contents from "../../routes/contentRoutes";
import LoginPage from "../../pages/auth/LoginPage/LoginPage";
import Login from "../../pages/auth/LoginUser/login/Login";
import Register from "../../pages/auth/LoginUser/register/Register";
import ForgotPassword from "../../pages/auth/LoginUser/forgot-password/ForgotPassword";
import Order from "../../Components/User/order/Order";
import LandingPage from "../../pages/landing/LandingPage";
import AboutUs from "../../Components/LandingComponent/about-us/AboutUs";
import Phaply from "../../Components/LandingComponent/phap-ly/Phaply";
import ChinhSach from "../../Components/LandingComponent/chinh-sach/ChinhSach";
import DaiLy from "../../Components/LandingComponent/dai-ly/DaiLy";

// const Login = lazy(() => import('../../pages/presentation/auth/Login'));
const ContentRoutes = () => {
  return (
    <Routes>
      {contents.map((page) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Route key={page.path} {...page} />
      ))}
      <Route path="/" element={<LandingPage />} />
      <Route path="/ve-chung-toi" element={<AboutUs />} />
      <Route path="/mua-hang" element={<Order />} />
      <Route path="/phap-ly" element={<Phaply />} />
      <Route path="/dai-ly" element={<DaiLy />} />
      <Route path="/chinh-sach" element={<ChinhSach />} />
      <Route path="/dang-nhap" element={<Login />} />
      <Route path="/dang-ky" element={<Register />} />
      <Route path="/quen-mat-khau" element={<ForgotPassword />} />
      <Route path="/dang-nhap-admin" element={<LoginPage />} />
    </Routes>
  );
};

export default ContentRoutes;
