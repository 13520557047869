import React, { lazy } from "react";
import { adminDashboardMenu, adminMenu, demoPagesMenu } from "../menu";
const ADMIN = {
  TRANSACTIONS: lazy(() =>
    import("../pages/AdminManager/Transactions/Transactions")
  ),
  ORDERS: lazy(() => import("../pages/AdminManager/Orders/Orders")),
  USERS: lazy(() => import("../pages/AdminManager/Users/Users")),
  REGISTERUSER: lazy(() =>
    import("../pages/Admin/RegisterEmail/RegisterEmail")
  ),
  FUNDS: lazy(() =>
  import("../pages/Admin/Funds/AdminFund")
),
};
const LANDING = {
  ORDERS: lazy(() => import("../pages/Admin/Orders/Orders")),
  ADMINDASHBOARD: lazy(() =>
    import("../pages/Admin/AdminDashboard/AdminDashboardPage")
  ),
  RECHARGE: lazy(() => import("../pages/Admin/AdminTransactions/RechargePage")),
  WITHDRAW: lazy(() => import("../pages/Admin/AdminTransactions/WithdrawPage")),
  GIFT: lazy(() => import("../pages/Admin/AdminTransactions/GiftPage")),
  TRANSACTIONS: lazy(() =>
    import("../pages/Admin/AdminTransactions/Transactions")
  ),
  SETTINGS: lazy(() => import("../pages/Admin/SettingAccount/SettingAccount")),
  Systemtree: lazy(() =>
    import("../pages/Admin/UserManagerment/UserTree/UserTree")
  ),
  ALLUSERS: lazy(() =>
    import("../pages/Admin/UserManagerment/AllUser/AllUser")
  ),
  LANDING: lazy(() => import("../pages/landing/LandingPage")),
  ABOUTUS: lazy(() =>
    import("../Components/LandingComponent/about-us/AboutUs")
  ),
};
const presentation = [
  {
    path: adminDashboardMenu.dashboard.path,
    element: <LANDING.ADMINDASHBOARD />,
  },
  {
    path: adminDashboardMenu.users.subMenu.systemtree.path,
    element: <LANDING.Systemtree />,
  },
  {
    path: adminDashboardMenu.order.path,
    element: <LANDING.ORDERS />,
  },
  {
    path: adminDashboardMenu.users.subMenu.allmems.path,
    element: <LANDING.ALLUSERS />,
  },
  {
    path: adminDashboardMenu.accountsettings.path,
    element: <LANDING.SETTINGS />,
  },
  {
    path: adminMenu.accountsettings.path,
    element: <LANDING.SETTINGS />,
  },
  {
    path: adminDashboardMenu.transactions.subMenu.recharge.path,
    element: <LANDING.RECHARGE />,
  },
  {
    path: adminDashboardMenu.transactions.subMenu.withdraw.path,
    element: <LANDING.WITHDRAW />,
  },
  {
    path: adminDashboardMenu.transactions.subMenu.gift.path,
    element: <LANDING.GIFT />,
  },
  {
    path: adminDashboardMenu.transactions.subMenu.transactions.path,
    element: <LANDING.TRANSACTIONS />,
  },
  {
    path: adminMenu.adminTransactions.path,
    element: <ADMIN.TRANSACTIONS />,
  },
  {
    path: adminMenu.adminTransactions.subMenu.adminRecharge.path,
    element: <ADMIN.TRANSACTIONS type={0} />,
  },
  {
    path: adminMenu.adminTransactions.subMenu.adminTransfer.path,
    element: <ADMIN.TRANSACTIONS type={2} />,
  },
  {
    path: adminMenu.adminTransactions.subMenu.adminWithdraw.path,
    element: <ADMIN.TRANSACTIONS type={1} />,
  },
  {
    path: adminMenu.adminTransactions.subMenu.adminTransactions.path,
    element: <ADMIN.TRANSACTIONS  />,
  },
  {
    path: adminMenu.adminOrders.path,
    element: <ADMIN.ORDERS />,
  },
  {
    path: adminMenu.allusers.path,
    element: <ADMIN.USERS />,
  },
  {
    path: adminMenu.usersFund.path,
    element: <ADMIN.FUNDS />,
  },
  {
    path: adminMenu.registerEmail.path,
    element: <ADMIN.REGISTERUSER />,
  },
  {
    path: adminMenu.admintree.path,
    element: <LANDING.Systemtree />,
  },
  // {
  //   path: demoPagesMenu.landingPage.path,
  //   element: <LANDING.LANDING />,
  // },
  // {
  //   path: demoPagesMenu.aboutUs.path,
  //   element: <LANDING.ABOUTUS />,
  // },
];
const documentation = [];
const contents = [...presentation, ...documentation];

export default contents;
