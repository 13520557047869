import React from "react";
import styles from "./aboutUs.module.css";

const AboutUs = () => {
  return (
    <div className={styles.aboutUs}>
      <div className={styles.aboutUs__section1}>
        <div className={styles.section1__layer}></div>
        <div className={styles.section1__content}>
          <h1>Về Chúng Tôi</h1>
          <div>
            Đến với KTV, gặp gỡ, cảm nhận và cùng sẻ chia mọi phương diện hợp
            tác trong tương lai, để thấy được sự khác biệt và những giá trị mà
            chúng tôi mang đến cho bạn. Chúng tôi không chỉ cung cấp sản phẩm,
            mà còn mang đến một tinh thần và sứ mệnh vững chắc để hỗ trợ cộng
            đồng trong việc chăm sóc sức khỏe và phát triển bền vững.
          </div>
        </div>
      </div>
      <div className={styles.aboutUs__section2}>
        <div className={styles.section2__content}>
          <h2 className={styles.section2__contentHeading}>
            Sứ Mệnh của Chúng Tôi
          </h2>
          <div className={styles.section2__contentBody}>
            Tại KTV, chúng tôi cam kết đem đến sản phẩm dinh dưỡng thiên
            nhiên cao cấp nhất, không chỉ đáp ứng nhu cầu dinh dưỡng mà còn tạo
            ra trải nghiệm tuyệt vời cho khách hàng. Sứ mệnh của chúng tôi không
            chỉ là về việc cung cấp thức ăn cho cơ thể mà còn là về việc tạo ra
            một cộng đồng chia sẻ lối sống lành mạnh và ý thức về sức khỏe.
          </div>
        </div>
        <div className={styles.section2__content}>
          <h2 className={styles.section2__contentHeading}>Tầm Nhìn</h2>
          <div className={styles.section2__contentBody}>
            Chúng tôi mong muốn xây dựng một thế giới nơi mọi người đều có thể
            tận hưởng cuộc sống khỏe mạnh và tràn đầy năng lượng thông qua việc
            sử dụng những sản phẩm dinh dưỡng tự nhiên. Chúng tôi tin rằng sức
            khỏe là nguồn động viên quan trọng để thực hiện mọi ước mơ và mục
            tiêu trong cuộc sống.
          </div>
        </div>
        <div className={styles.section2__content}>
          <h2 className={styles.section2__contentHeading}>
            Chất Lượng và An Toàn
          </h2>
          <div className={styles.section2__contentBody}>
            Chúng tôi tự hào về việc chọn lựa các thành phần tự nhiên và hữu cơ,
            không chứa hóa chất độc hại. Sản phẩm của chúng tôi được sản xuất
            với tiêu chuẩn cao nhất để đảm bảo an toàn và chất lượng tuyệt vời
            cho mọi người.
          </div>
        </div>
        <div className={styles.section2__content}>
          <h2 className={styles.section2__contentHeading}>
            Cam Kết Đối Với Cộng Đồng
          </h2>
          <div className={styles.section2__contentBody}>
            Chúng tôi hiểu rằng sức khỏe không chỉ là cá nhân mà còn là cộng
            đồng. Vì vậy, chúng tôi cam kết hỗ trợ các hoạt động và tổ chức có
            liên quan đến sức khỏe và phát triển cộng đồng. Mỗi gói  KTV mà bạn
            sử dụng không chỉ là một sự đầu tư vào sức khỏe cá nhân mà còn là
            đóng góp vào sự phát triển bền vững của cả cộng đồng.
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
