import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App/App";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthContextProvider } from "./contexts/authContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { LoadingProvider } from "./contexts/loadingContext";
import { NotificationProvider } from "./contexts/notificationContext";
import ScrollToTop from "./Components/Common/ScrollToToop";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LoadingProvider>
    <AuthContextProvider>
      <NotificationProvider>
        <Router>
          {/* <ScrollToTop> */}
            <App />
          {/* </ScrollToTop> */}
        </Router>
      </NotificationProvider>
    </AuthContextProvider>
  </LoadingProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
