import React, { createContext, useState, useEffect, useMemo } from "react";
import { useLoading } from "./loadingContext";

const authContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [token, setToken] = useState(
    localStorage.getItem("access_token") || ""
  );
  const [userData, setUserData] = useState({});
  const [openMenuMobile, setOpenMenuMobile] = useState(false);
  const [asideStatus, setAsideStatus] = useState(false);
  const [noti, setNoti] = useState([]);
  const { show, hide } = useLoading();
  // const handleGetProfile = async () => {
  //   const res = await getMe();
  //   try {
  //     if (res?.status_code == 200) {
  //       setUserData(res?.data);
  //       const timer = setTimeout(() => {
  //         hide();
  //       }, 1000);
  //       return () => clearTimeout(timer);
  //       // hide();
  //     } else {
  //       setUserData({});
  //       // hide();
  //       const timer = setTimeout(() => {
  //         hide();
  //       }, 1000);
  //       return () => clearTimeout(timer);
  //     }
  //   } catch (err) {
  //     throw err;
  //   }
  // };
  // useEffect(() => {
  //   if (token && token.length !== 0) {
  //     handleGetProfile();
  //   }
  // }, [token]);
  useEffect(() => {
    localStorage.setItem("access_token", token);
  }, [token]);
  const value = useMemo(
    () => ({
      asideStatus,
      setAsideStatus,
      token,
      setToken,
      userData,
      setUserData,
      openMenuMobile,
      setOpenMenuMobile,
      noti,
      setNoti,
    }),
    [token, userData, asideStatus, openMenuMobile]
  );
  return <authContext.Provider value={value}>{children}</authContext.Provider>;
};
export default authContext;
