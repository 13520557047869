import React from "react";
import styles from "./landingFooter.module.css";
import { Form } from "react-bootstrap";
import bct from "../../assets/img/landing-page/bct.png";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useNotification } from "../../contexts/notificationContext";
import { sendUserEmail } from "../../api/userApi";
import { useMedia } from "react-use";

const LandingFooter = () => {
  const { showNoti } = useNotification();
  const isWide = useMedia("(min-width: 783px)");
  const portrait = window.matchMedia("(orientation: portrait)").matches;
  const formik = useFormik({
    initialValues: {
      email: "",
    },
  });
  const handleSendEmail = async () => {
    if (formik.values.email === "") {
      showNoti("Chưa điền email", "error");
      return;
    }
    const input = {
      email: formik.values.email,
    };
    try {
      const res = await sendUserEmail(input);
      if (res) {
        showNoti("Thành công", "success");
      }
    } catch (err) {
      showNoti(
        err?.response?.data?.error_msg?.email
          ? err?.response?.data?.error_msg?.email
          : err?.response?.data?.error_msg,
        "error"
      );
    }
  };
  return (
    <>
      <div className={styles.landingFooter}>
        <div className={styles.landingFooter__register}>
          <div className="d-flex justify-content-center">
            <p className={styles.register__note}>
              Chào mừng bạn đến với gia đình KTV, nơi mà chăm sóc sức khỏe trở
              nên đơn giản và đầy ý nghĩa. Cùng chúng tôi hướng tới một cuộc
              sống lành mạnh, thịnh vượng hơn!
            </p>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSendEmail();
            }}
          >
            <div className="d-flex justify-content-center mb-3">
              <input
                value={formik.values.email}
                id="email"
                onChange={formik.handleChange}
                className={styles.register__input}
                type="text"
                placeholder="Email của bạn"
              />
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                onClick={handleSendEmail}
                className={styles.landingFooter__registerButton}
              >
                ĐĂNG KÝ
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className={styles.footer}>
        <div
          className={`d-flex aligns-items-center ${
            portrait ? "flex-column gap-2" : "gap-5"
          }`}
        >
          <h2 style={{ fontWeight: "700" }}>THÔNG TIN CÔNG TY</h2>
          <img src={bct} className={styles.bct__image} alt="" />
        </div>
        <div className={styles.footerList}>
          <div>
            <p>Tên công ty: CÔNG TY CỔ PHẦN TẬP ĐOÀN KHỎE ĐẸP HẠNH PHÚC</p>
            <p>
              Trụ sở: thôn Vân Lũng, xã An Khánh, huyện Hoài Đức, thành phố Hà
              Nội, Việt Nam
            </p>
            <p>Hotline: 039.686.5179</p>
            <p style={{ whiteSpace: isWide ? "nowrap" : "" }}>
              Email: khoehanhphuc99@gmail.com
            </p>
            <p>Mã số DKKD: 0110566704</p>
          </div>
          <div>
            <Link to={"/ve-chung-toi"} target="_blank">
              Về chúng tôi
            </Link>
            <br />
            <Link to={"/chinh-sach"} target="_blank">
              Chính sách
            </Link>
            <br />
            <Link to={"/phap-ly"} target="_blank">
              Pháp lý
            </Link>
            <br />
          </div>
          {/* <div>
            <Link>Hình thức thanh toán</Link>
            <br />
            <Link>Pháp lý</Link>
            <br />
            <Link>Điều khoản</Link>
            <br />
          </div> */}
          <div>
            <p>THEO DÕI CHÚNG TÔI TRÊN</p>
            <a href="">Facebook</a>
            <br />
            <a href="">Zalo</a>
            <br />
            <a href="">Youtube</a>
            <br />
          </div>
        </div>
        <div className={styles.footer__copyright}>
          © 2023 - Bản quyền thuộc về Công ty Cổ Phần Tập Đoàn Khỏe Đẹp Hạnh
          Phúc
        </div>
      </div>
    </>
  );
};

export default LandingFooter;
