import { Alert, Snackbar } from "@mui/material";
import React, { createContext, useContext, useState } from "react";

export const notificationContext = createContext();
export const useNotification = () => useContext(notificationContext);
const Notification = ({ open, handleClose, color, info }) => {
  return (
    <>
      <Snackbar
        sx={{ zIndex: "10000000" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical:"top", horizontal:'right' }}
      >
        <Alert onClose={handleClose} severity={color} sx={{ width: "100%" }}>
          {info}
        </Alert>
      </Snackbar>
    </>
  );
};
export const NotificationProvider = (props) => {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("success");
  const [info, setInfo] = useState("Notification");
  const showNotification = (msg, clg) => {
    setInfo(msg);
    setColor(clg);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };
  const setMessage = (msg) => {
    setInfo(msg);
  };
  const changeColor = (color) => {
    setColor(color);
  };
  const show = () => {
    setLoading(true);
  };
  const hide = () => {
    setLoading(false);
  };
  return (
    <notificationContext.Provider
      value={{
        loading: loading,
        show: show,
        hide: hide,
        changeColor: changeColor,
        setMsg: setMessage,
        showNoti: showNotification,
      }}
    >
      {loading && (
        <Notification
          open={loading}
          handleClose={hide}
          info={info}
          color={color}
        />
      )}
      {props.children}
    </notificationContext.Provider>
  );
};
