import React from "react";
import styles from "../chinh-sach/chinhsach.module.css";

const DaiLy = () => {
  return (
    <>
      <div className={styles.chinhSach__header}>
        <div className={styles.header__layer}></div>
        <h2>Đại lý</h2>
      </div>
      <div className={styles.chinhSach}>
        <div
          className={styles.chinhSach__body}
          style={{ width: "fit-content" }}
        >
          Chúng tôi đang trong quá trình cập hệ thống đại lý
        </div>
      </div>
    </>
  );
};

export default DaiLy;
