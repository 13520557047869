import React from "react";
import AsideRoutes from "../layout/Aside/AsideRoutes";
import Wrapper from "../layout/Wrapper/Wrapper";
const App = () => {
  return (
    <div className="body">
      <div id="send-code-button"></div>
      <AsideRoutes />
      <Wrapper />
    </div>
  );
};

export default App;
