import React, { Suspense } from 'react';
import ContentRoutes from './ContentRoutes';
import PageWrapper from '../PageWrapper/PageWrapper';

const LOADING = (
	// <PageWrapper></PageWrapper>
	<></>
);

const Content = () => {
	return (
		<main className='content'>
			<Suspense fallback={LOADING}>
				<ContentRoutes />
			</Suspense>
		</main>
	);
};

export default Content;
