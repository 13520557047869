import React, { useEffect, useState } from "react";
import styles from "./landing.module.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import section1_image from "../../assets/img/landing-page/section1-image.svg";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.css";
import botdinhduong from "../../assets/img/landing-page/product3.png";
import mamdaunanh from "../../assets/img/landing-page/mam-dau-nanh.webp";
import gaolut from "../../assets/img/landing-page/gao-lut.webp";
import phuongthucbitruyen from "../../assets/img/landing-page/phuong-thuc-bi-truyen.webp";
import vungden from "../../assets/img/landing-page/Frame 21.jpg";
import thaoduoc from "../../assets/img/landing-page/Frame 23.jpg";
import shoppingcartimage from "../../assets/icons/shopping-cart.svg";
import section6image from "../../assets/img/landing-page/section6-image.webp";
import loanpham from "../../assets/img/landing-page/loanpham.webp";
import dinhtramanh from "../../assets/img/landing-page/Frame 21.png";
import triduc from "../../assets/img/landing-page/Frame 22.png";
import nguyenkhactung from "../../assets/img/landing-page/Frame 23.png";
import mymy from "../../assets/img/landing-page/Frame 24.png";
import section8_image from "../../assets/img/landing-page/section8-image.webp";
import camket from "../../assets/img/landing-page/camketchionhhang.png";
import khuyenmai from "../../assets/img/landing-page/khuyenmaikhung.png";
import thanhtoan from "../../assets/img/landing-page/thahtoan.png";
import giaohang from "../../assets/img/landing-page/giaohang.png";
import doingu from "../../assets/img/landing-page/doingu.png";
import Cart from "../_layout/_cart/Cart";
import product1 from "../../assets/img/landing-page/product1.webp";
import product2 from "../../assets/img/landing-page/product2.webp";
import product3 from "../../assets/img/landing-page/product3.webp";
import product4 from "../../assets/img/landing-page/product4.webp";
import product5 from "../../assets/img/landing-page/product5.webp";

const LandingPage = () => {
  const portrait = window.matchMedia("(orientation: portrait)").matches;
  const navigate = useNavigate();
  const [indexTP, setIndexTP] = useState(0);
  const [indexReview, setIndexReview] = useState(0);
  const [descripKTV, setDescripKTV] = useState(true);
  const [openCart, setOpenCart] = useState(false);
  const [cartData, setCartData] = useState(0);
  const listItem = [
    {
      src: product5,
    },
    {
      src: product2,
    },
    {
      src: product3,
    },
    {
      src: product4,
    },
  ];

  const listThanhPhan = [
    {
      name: "Gạo lứt",
      image: gaolut,
      description: `90% vitamin B6, 80% vitamin B1, 0,5% mamgan, 77% vitamin B3Cám gạo lứt chứa 84mg magie và đặc biệt dầu của cám gạo có khả năng đốt cháy calori cao hơn, điều hòa huyết áp, làm giảm cholesterol xấu, ngăn ngừa các bệnh tim mạch.`,
    },
    {
      name: "Vừng đen",
      image: vungden,
      description:
        "Hàm lượng canxi trong 100g vừng đen là 780mg, gấp 7,5 lần sữa và 14 lần trứng. Nếu bổ sung thường xuyên vào các bữa ăn, trẻ sẽ lớn nhanh hơn. Mè đen có tác dụng giảm cholesterol, có chất chống oxy hóa và giảm nguy cơ mắc bệnh tim, bệnh tiểu đường, chống ung thư.",
    },
    {
      name: "Mầm đậu nành",
      image: mamdaunanh,
      description:
        "Rất giàu protein, chất xơ, acid béo và các hợp chất thực vật có lợi như isoflavone. Chúng có thể không chỉ giúp giảm cân mà còn tăng cường sức khỏe của tim và xương.",
    },
    {
      name: "Cỏ ngọt",
      image: thaoduoc,
      description:
        "Cỏ ngọt với khả năng làm ngọt tự nhiên mà không tăng đường huyết, là một nguồn thay thế độc đáo cho đường và các chất làm ngọt nhân tạo. Cây cỏ ngọt giúp không tăng đường huyết, giảm nguy cơ ung thư tuyến tụy, giảm lượng calo, không gây sâu răng, có thể hỗ trợ kiểm soát cân nặng và chứa các chất chống ô nhiễm.",
    },
    {
      name: "Các phương thức bí truyền",
      image: phuongthucbitruyen,
      description: "Tác dụng : Thanh , điều, bổ, phòng cho cơ thể.",
    },
  ];

  const listSection9 = [
    {
      name: "Cam kết chính hãng",
      image: camket,
    },
    {
      name: "Khuyến mãi khủng",
      image: khuyenmai,
    },
    {
      name: "Thanh toán tiện lợi",
      image: thanhtoan,
    },
    {
      name: "Giao hàng toàn quốc",
      image: giaohang,
    },
    {
      name: "Đội ngũ chuyên nghiệp",
      image: doingu,
    },
  ];

  const listKhacHangReview = [
    {
      name: "Loan Phạm",
      image: loanpham,
      review:
        "Dinh dưỡng KTV thực sự là sự kết hợp hoàn hảo giữa chất lượng và giá trị dinh dưỡng. Tôi đã thấy sự cải thiện rõ rệt về sức khỏe của mình sau khi sử dụng sản phẩm này.",
    },
    {
      name: "Đinh Trâm Anh",
      image: dinhtramanh,
      review:
        "Không chỉ giúp tôi tăng cường năng lượng mỗi ngày, dinh dưỡng KTV còn có hương vị tự nhiên và dễ uống. Đây là một lựa chọn tuyệt vời cho những người muốn duy trì lối sống lành mạnh.",
    },
    {
      name: "Trí Đức",
      image: triduc,
      review:
        "Tôi thực sự ấn tượng với thành phần tự nhiên của dinh dưỡng KTV. Đây là một sản phẩm không chứa hóa chất độc hại, giúp tôi yên tâm về sự an toàn cho sức khỏe của mình.",
    },
    {
      name: "Nguyễn Khắc Tùng",
      image: nguyenkhactung,
      review:
        "Dinh dưỡng KTV có hàm lượng protein và dưỡng chất cao, phù hợp cho người tập thể dục. Tôi cảm thấy sự khỏe mạnh và phục hồi nhanh chóng sau mỗi buổi tập.",
    },
    {
      name: "Khởi My",
      image: mymy,
      review:
        "Mùi vị tự nhiên của dinh dưỡng KTV  thực sự làm cho việc uống trở nên dễ dàng hơn. Không gặp khó khăn nào khi tích hợp sản phẩm này vào chế độ dinh dưỡng hàng ngày.",
    },
  ];

  const handleSelectIndexTP = (idx) => {
    setIndexTP(idx);
  };
  const handleSelectIndexReview = (idx) => {
    setIndexReview(idx);
  };

  useEffect(() => {
    localStorage.removeItem("registerSuccess");
  }, []);

  return (
    <div className={styles.landingPage}>
      {openCart ? (
        <div
          className={styles.cardOverLay}
          onClick={() => setOpenCart(false)}
        ></div>
      ) : (
        <></>
      )}
      <Cart
        open={openCart}
        setOpen={setOpenCart}
        data={cartData}
        setData={setCartData}
      />
      <div className={styles.landingPage__section1}>
        <div className={styles.section1__body}>
          <h1>DINH DƯỠNG THIÊN NHIÊN KTV</h1>
          <h2 className="my-3">MẠNH KHOẺ ĐỂ HẠNH PHÚC</h2>
          <img
            src={section1_image}
            alt="section-1-image"
            className={styles.section1__image}
          />
          <div className={styles.section1__description}>
            Đem đến cho cộng đồng sản phẩm chăm sóc sức khỏe bắt nguồn từ thiên
            nhiên, áp dụng quy trình khoa học và công nghệ tiên tiến.
          </div>
          {/* <Link
            to={localStorage?.getItem("access_token") ? "/mua-hang" : "/login"}
            style={{ textDecoration: "none" }}
          > */}

          <button
            onClick={() => {
              setCartData((old) => ++old);
              setOpenCart(true);
            }}
            className={styles.section1__button}
          >
            ĐẶT HÀNG NGAY
          </button>
          {/* </Link> */}
        </div>
      </div>
      <div className={styles.landingPage__section2}>
        <div>
          <div>
            <h2 className={styles.section2__quote1}>
              “DÙNG THUỐC ĐỂ CHỮA BỆNH LÀ HẠ SÁCH
            </h2>
            <h2 className={styles.section2__quote2}>
              THƯỢNG SÁCH LÀ KHÔNG DÙNG THUỐC MÀ CHỮA ĐƯỢC BỆNH”
            </h2>
          </div>
          <div>
            <h3 className={styles.section2__text1}>
              HẢI THƯỢNG LÃN ÔNG LÊ HỮU TRÁC{" "}
            </h3>
            <h3 className={styles.section2__text2}>(1720 -1791)</h3>
          </div>
        </div>
      </div>
      <div className={styles.landingPage__section3}>
        <div className={styles.section3__carousel}>
          <Carousel>
            {listItem?.map((item, idx) => (
              <Carousel.Item key={idx} interval={2000}>
                <img
                  className={styles.section3__carouselItem}
                  src={item?.src}
                  alt={"item"}
                />
                {/* <Carousel.Caption>
                  <h3>First slide label</h3>
                  <p>
                    Nulla vitae elit libero, a pharetra augue mollis interdum.
                  </p>
                </Carousel.Caption> */}
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        <div className={styles.section3__itemInfomation}>
          <p className={styles.section3__itemName}>
            Dinh dưỡng thiên nhiên KTV
          </p>
          <p className={styles.section3__itemPrice}>Giá: 3.300.000đ</p>
          {/* <Link
            to={localStorage?.getItem("access_token") ? "/mua-hang" : "/login"}
            style={{ textDecoration: "none" }}
          > */}
          <button
            className={styles.section3__orderButton}
            onClick={() => {
              setCartData((old) => ++old);
              setOpenCart(true);
            }}
          >
            <img
              src={shoppingcartimage}
              alt=""
              className={styles.section3__shoppingIcon}
            />
            ĐẶT HÀNG NGAY
          </button>
          {/* </Link> */}
        </div>
      </div>
      <div className={styles.landingPage__section4}>
        <h2 className={styles.section4__heading}>Thành phần</h2>
        <div className={styles.section4__list}>
          {portrait ? (
            <>
              <Carousel activeIndex={indexTP} onSelect={handleSelectIndexTP}>
                {listThanhPhan?.map((item, idx) => (
                  <Carousel.Item key={idx}>
                    <img
                      className={styles.section4__contentItemImage}
                      src={item?.image}
                      alt={"item"}
                    />
                    {/* <Carousel.Caption>
                    <div className={styles.section4__contentItemDescription}>
                      {item?.description}
                    </div>
                    <p className={styles.section4__contentItemName}>
                      {item?.name}
                    </p>
                  </Carousel.Caption> */}
                  </Carousel.Item>
                ))}
              </Carousel>
              <div className={styles.section4__carouselDescription}>
                <h2 className={styles.section4__contentItemName}>
                  {listThanhPhan?.find((item, idx) => idx === indexTP).name}
                </h2>
                {
                  listThanhPhan?.find((item, idx) => idx === indexTP)
                    .description
                }
              </div>
            </>
          ) : (
            <div className={styles.section4__content}>
              {listThanhPhan?.map((item, idx) => (
                <div key={idx} className={styles.section4__contentItem}>
                  <img
                    src={item?.image}
                    className={styles.section4__contentItemImage}
                    alt="anh-thanh-phan"
                  />
                  <div>
                    <p className={styles.section4__contentItemName}>
                      {item?.name}
                    </p>
                    <div className={styles.section4__contentItemDescription}>
                      {item?.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={styles.landingPage__section5}>
        <h2 className={styles.section5__heading}>
          DINH DƯỠNG THIÊN NHIÊN KTV
        </h2>

        <div className={styles.section5__content}>
          <div>
            <p>
              Là sản phẩm thực phẩm độc đáo được tạo ra từ những sản vật tự
              nhiên mà thường ngày chúng ta vẫn thường sử dụng như gạo lứt, muối
              mè, đậu tương… cùng với phương thức sản xuất bí truyền đặc biệt.
            </p>
            <p>
              KTV đã tạo nên một sản phẩm có công dụng kỳ diệu trong việc thanh
              lọc cơ thể, đào thải độc tố một cách hiệu quả, nhanh chóng và an
              toàn. Đây không phải là một thực phẩm dùng để ăn uống thông thường
              mà thực chất nó là một chương trình thanh lọc cơ thể toàn diện và
              hiệu quả nhưng rất đơn giản và an toàn.
            </p>
          </div>
          <div className={descripKTV ? styles.hiddenContent : ""}>
            <p>
              Chương trình thải độc này dựa vào nguyên lý cung cấp đủ dinh dưỡng
              thiết yếu lành mạnh, với lượng calo thấp để cơ thể đủ năng lượng
              duy trì hoạt động hàng ngày, một mặt không đưa thêm chất độc vào
              cơ thể qua việc ăn uống những thực phẩm dư thừa độc tố hoặc những
              chất không cần thiết, mặt khác việc cung cấp calo thấp cũng giúp
              cơ thể đốt cháy những năng lượng dư thừa đã tích tụ lâu ngày, độc
              tố trong cơ thể từ từ được đào thải ra ngoài. Tác dụng chính là
              đào thải độc tố và thanh lọc cơ thể.
            </p>
          </div>
          <div className={descripKTV ? styles.hiddenContent : ""}>
            <p>
              Nhưng do hiệu quả kỳ diệu của việc đào thải độc tố mà sản phẩm lại
              có 2 tác dụng phụ tuyệt vời, đó là giảm cân và phục hồi sức khỏe
              và cấu trúc cơ thể. Do tích quá nhiều độc tố, khiến hệ tiêu hóa bị
              tổn thương, hệ vi khuẩn đường ruột bị biến đổi, giảm độ nhạy cảm
              của insulin, làm cho cơ thể càng ngày càng tích thêm mỡ dư thừa,
              nếu tích mỡ ở bên ngoài, chúng ta thấy cơ thể béo phì, xấu xí, nếu
              tích bên trong nội tạng, cơ thể tuy không béo phì xấu xí nhưng
              những biểu hiện của nó như mệt mỏi mạn tính, tính tình nóng nảy,
              trí nhớ kém, mất tập trung, da dẻ nhiều mụn, nám nổi lên hoặc các
              triệu chứng bệnh tật… là không tránh khỏi.
            </p>
          </div>
          <div className={descripKTV ? styles.hiddenContent : ""}>
            <p>
              Việc thanh lọc cơ thể, khiến cân nặng dư thừa (bản chất là độc tố
              và những thứ dư thừa không cần thiết) bị loại bỏ, cơ thể trở nên
              sạch sẽ, lành mạnh vì vậy các tế bào hư hỏng được phục hồi trở
              lại, hệ thống tự chữa lành của cơ thể được kích hoạt trở lại và
              hoạt động trơn tru, nên cơ thể tự nó sẽ chữa lành nhiều thương tổn
              vi.
            </p>
          </div>
          {portrait ? (
            <div className="d-flex justify-content-center">
              {!descripKTV ? (
                <button
                  className={styles.readMoreButton}
                  onClick={() => setDescripKTV(!descripKTV)}
                >
                  Thu gọn
                </button>
              ) : (
                <button
                  className={styles.readMoreButton}
                  onClick={() => setDescripKTV(!descripKTV)}
                >
                  Xem thêm
                </button>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={styles.landingPage__section6}>
        <div>
          <h2>HỖ TRỢ ĐIỀU TRỊ CÁC TRƯỜNG HỢP</h2>
          <div>
            <ul>
              <li>Béo phì</li>
              <li>Thải độc sâu</li>
              <li>Hỗ trợ tiểu đường</li>
              <li>Máu nhiễm mỡ</li>
              <li>Gan nhiễm mỡ</li>
              <li>Men gan cao</li>
            </ul>
            <ul>
              <li>Huyết áp thấp. Huyết áp cao</li>
              <li>Tiền đình</li>
              <li>Đại tràng</li>
              <li>Sỏi bùn ở mật</li>
              <li>Các loại u trong nội tạng</li>
              <li>Hen suyễn, viêm phế quản</li>
            </ul>
          </div>
        </div>
        <img src={section6image} alt="" className={styles.section6__image} />
      </div>
      <div className={styles.landingPage__section7}>
        <h2 className={styles.section7__heading}>HƯỚNG DẪN SỬ DỤNG</h2>
        {/* <p className={styles.section7__heading}>HƯỚNG DẪN SỬ DỤNG</p> */}
        <div className={styles.section7__content}>
          <div>
            Trước hết cần có niềm tin vào việc sử dụng sản phẩm và kiên trì để
            đạt được mục tiêu mong muốn. Khi bắt đầu thực hiện tuyệt đối không
            được ăn bất cứ thứ gì ngoài sản phẩm THỰC DƯỠNG, chỉ được uống thêm
            nước lọc (hoặc nước sôi để nguội).
          </div>
          <ul>
            <li>
              Cách sử dụng:
              <p>
                Một gói nhỏ chia làm 7 lần, uống trong 1 ngày, mỗi lần cách nhau
                2h, hòa với nước sôi, mỗi lần 300ml.
              </p>
            </li>
            <li>
              Liệu trình:
              <p>
                1 túi/hộp sản phẩm, trong đó có 7 túi zip nhỏ, dùng cho 7 ngày.
              </p>
            </li>
          </ul>
          <div>
            Mỗi túi nhỏ dùng cho 1 ngày, thông thường nên pha túi zip đó thành 7
            lần uống với nước sôi, mỗi lần pha 1/7 túi zip nhỏ với 150ml nước
            sôi, chờ 3 – 5 phút rồi cho thêm 100ml nước sôi để nguội để được
            250ml hỗn hợp dung dịch rồi uống. Mỗi ngày uống 7 cốc đã pha như
            trên, khoảng 1,5 - 2 giờ uống một lần.
          </div>
          <div>
            Khi nào mệt mỏi, cần tăng cường vận động để độc tố thải nhanh hơn cơ
            thể sẽ sạch hơn và hết mệt, không nên nằm một chỗ, càng nằm càng mệt
            do độc tố bị cản trở trên đường đào thải ra ngoài. Tùy theo từng thể
            trạng sức khỏe của từng người có thể thực hiện liệu trình 7 - 14 -
            21 - 28 ngày hoặc lâu hơn.
          </div>
        </div>
      </div>
      <div className={styles.landingPage__section8}>
        <div>
          {portrait ? (
            <></>
          ) : (
            <h2 className={styles.section8__heading}>
              GIÁ TRỊ KHI SỬ DỤNG DINH DƯỠNG THIÊN NHIÊN - KTV
            </h2>
          )}
          <ul>
            <li>Dinh dưỡng đúng đủ cho cơ thể.</li>
            <li>Giúp cơ thể đào thải độc tố.</li>
            <li>Giúp cơ thể đốt cháy mỡ thừa.</li>
            <li>Giúp cơ thể cân bằng độ PH.</li>
            <li>Giúp cơ thể cân bằng âm dương.</li>
            <li>Giúp cơ thể trẻ hóa tế bào.</li>
          </ul>
        </div>
        <div>
          {portrait ? (
            <h2 className={styles.section8__heading}>
              GIÁ TRỊ KHI SỬ DỤNG DINH DƯỠNG THIÊN NHIÊN - KTV
            </h2>
          ) : (
            <></>
          )}
          <img src={section8_image} alt="" className={styles.section8__image} />
        </div>
      </div>
      <div className={styles.landingPage__section9}>
        <div className={styles.section9__list}>
          {listSection9?.map((item, idx) => (
            <div key={idx} className={styles.section9__listItem}>
              <p className={styles.section9__listName}>{item?.name}</p>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100px" }}
              >
                <img
                  src={item?.image}
                  alt=""
                  className={styles.section9__listImage}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.landingPage__section10}>
        <h2 className={styles.section10__heading}>ĐÁNH GIÁ KHÁCH HÀNG</h2>
        <div className={styles.section10__content}>
          {portrait ? (
            <>
              <Carousel
                activeIndex={indexReview}
                onSelect={handleSelectIndexReview}
              >
                {listKhacHangReview?.map((item, idx) => (
                  <Carousel.Item key={idx}>
                    <img
                      className={styles.section10__contentItemImage}
                      src={item?.image}
                      alt={"item"}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
              <div className={styles.section10__carouselDescription}>
                <h2 className={styles.section4__contentItemName}>
                  {
                    listKhacHangReview?.find((item, idx) => idx === indexReview)
                      .name
                  }
                </h2>
                {
                  listKhacHangReview?.find((item, idx) => idx === indexReview)
                    .review
                }
              </div>
            </>
          ) : (
            listKhacHangReview?.map((item, idx) => (
              <div key={idx} className={styles.section10__contentItem}>
                <img
                  src={item?.image}
                  className={styles.section10__contentItemImage}
                  alt="anh-thanh-phan"
                />
                <div>
                  <p className={styles.section10__contentItemName}>
                    {item?.name}
                  </p>
                  <div className={styles.section10__contentItemReview}>
                    {item?.review}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
