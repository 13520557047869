import React, { useContext, useEffect, useState } from "react";
import styles from "./userLogin.module.css";
import ktv_logo_image from "../../../assets/img/ktv-logo-login.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import authContext from "../../../contexts/authContext";

const UserLoginPage = ({ children }) => {
  // console.log(window.screen.orientation, "type screen");
  const location = useLocation();
  const navigate = useNavigate();
  const checkRegisterSuccess =
    localStorage.getItem("registerSuccess") === "1" ? true : false;
  useEffect(() => {}, []);

  return (
    <div className={styles.loginUser}>
      {checkRegisterSuccess ? (
        <div className={styles.registerSuccess}>
          <div>
            <div className="d-flex justify-content-center">
              <Link to={"/"}>
                <img src={ktv_logo_image} alt="" className={styles.logoLogin} />
              </Link>
            </div>
            <h4 className="mt-5">
              Chúc mừng bạn đã đăng ký thành công. Vui lòng kiểm tra email để
              kích hoạt tài khoản.
            </h4>
            <button
              onClick={() => {
                // navigate("/dang-nhap");

                // localStorage.removeItem("registerSuccess");
                window.location.href = "https://mail.google.com/mail/u/";
              }}
            >
              Kiểm tra Email
            </button>
          </div>
          {/* <div className={styles.login__backgroundImage}></div> */}
        </div>
      ) : (
        <>
          <div className={styles.loginBackground}>
            <Link to={"/"}>
              <img src={ktv_logo_image} alt="" className={styles.logoLogin} />
            </Link>
            {/* <div className={styles.login__backgroundImage}></div> */}
          </div>
          <div className={styles.loginForm}>{children}</div>
        </>
      )}
    </div>
  );
};

export default UserLoginPage;
