import classNames from "classnames";
import React, {forwardRef, useContext, useEffect, useLayoutEffect} from "react";
import { useNavigate } from "react-router-dom";
import { demoPagesMenu } from "../../menu";
import authContext from "../../contexts/authContext";

const PageWrapper = forwardRef(
	({ isProtected, title, description, className, children }, ref) => {
		// useLayoutEffect(() => {
		// 	// @ts-ignore
		// 	document.getElementsByTagName('TITLE')[0].text = `${title ? `${title} | ` : ''}${
		// 		process.env.REACT_APP_SITE_NAME
		// 	}`;
		// 	// @ts-ignore
		// 	document
		// 		?.querySelector('meta[name="description"]')
		// 		.setAttribute('content', description || process.env.REACT_APP_META_DESC || '');
		// });
	
		const { token } = useContext(authContext);
		const navigate = useNavigate();
		useEffect(() => {
			if (token === '') {
				navigate(`../${demoPagesMenu.login.path}`);
			}
			return () => {};
		}, [token]);

		return (
			<div ref={ref} className={classNames( className)}>
				{children}
			</div>
		);
	},
);
export default PageWrapper;
