import React, { useContext, useState, useEffect } from "react";
import UserLoginPage from "../UserLoginPage";
import styles from "../userLogin.module.css";
import { MdOutlineMailOutline, MdLock } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { login, verifyEmail } from "../../../../api/authApi";
import { useNotification } from "../../../../contexts/notificationContext";
import authContext from "../../../../contexts/authContext";
import "../../../../styles/main.css";
import ktv_logo_image from "../../../../assets/img/ktv-logo-login.png";

const Login = () => {
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  const { showNoti } = useNotification();
  const { setToken, setUserData } = useContext(authContext);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState({
    email: "",
    password: "",
  });
  const portrait = window.matchMedia("(orientation: portrait)").matches;
  const handleLogin = async (e) => {
    e.preventDefault();
    if (loginForm?.email === "" || loginForm.password === "") {
      setErrorMsg({
        email: loginForm?.email === "" ? "Bạn chưa nhập email" : "",
        password: loginForm?.password === "" ? "Bạn chưa nhập mật khẩu" : "",
      });
      return;
    }
    const input = {
      email: loginForm.email,
      password: loginForm.password,
    };
    try {
      const res = await login(input);
      if (res) {
        showNoti("Đăng nhập thành công", "success");
        localStorage.setItem("access_token", res?.data?.token);
        setToken(res?.data?.token);
        setUserData(res?.data?.user);
        navigate("/");
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        setErrorMsg({
          email: "",
          password: "",
          ...err?.response?.data?.error_msg,
        });
      } else {
        showNoti(err?.response?.data?.error_msg, "error");
      }
    }
  };
  const handleVerify = async (id, code) => {
    const input = {
      user_id: id,
      verify_code: code,
    };
    try {
      const res = await verifyEmail(input);
      if (res) {
        showNoti("Xác minh email thành công", "success");
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        setErrorMsg({
          email: "",
          password: "",
          ...err?.response?.data?.error_msg,
        });
      } else {
        showNoti(err?.response?.data?.error_msg, "error");
      }
    }
  };
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params?.user_id && params?.verify_code) {
      handleVerify(params?.user_id, params?.verify_code);
    }
  }, [window.location]);
  const handleErrorMsg = (id) => {
    if (errorMsg[id] !== "") {
      return <small className="text-danger mb-3">{errorMsg[id]}</small>;
    }
  };

  return (
    <UserLoginPage>
      <div className={styles.login__Form}>
        {portrait ? (
          <div className="d-flex justify-content-center mb-5">
            <Link to={"/"}>
              <img src={ktv_logo_image} alt="" className={styles.logo} />
            </Link>
          </div>
        ) : (
          <></>
        )}
        <p className={styles.login__FormHeading}>Đăng Nhập</p>
        <p>Chào mừng bạn quay lại!</p>
        <form onSubmit={handleLogin}>
          <div>
            <div className={"formControl"}>
              <input
                value={loginForm?.email}
                onChange={(e) =>
                  setLoginForm({ ...loginForm, email: e.target.value })
                }
                type="text"
                placeholder="Email hoặc Số điện thoại"
                className={"formInput"}
              ></input>
              <MdOutlineMailOutline className={"formIcon"} />
            </div>
            {handleErrorMsg("email")}
            <div className={"formControl"}>
              <input
                value={loginForm?.password}
                onChange={(e) =>
                  setLoginForm({ ...loginForm, password: e.target.value })
                }
                type="password"
                placeholder="Mật khẩu"
                className="formInput"
              />
              <MdLock className={"formIcon"} />
            </div>
            {handleErrorMsg("password")}
          </div>

          <button
            type="submit"
            className="formSubmitButton"
            // onClick={handleLogin}
          >
            Đăng Nhập
          </button>
        </form>
        <div className={styles.login__register}>
          <div>
            <Link to={"/quen-mat-khau"}>Quên mật khẩu</Link>
          </div>
          <p>
            Bạn chưa có tài khoản? <Link to={"/dang-ky"}>Đăng ký</Link>
          </p>
        </div>
      </div>
    </UserLoginPage>
  );
};

export default Login;
