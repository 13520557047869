import React from "react";
import { RouteProps } from "react-router-dom";
import { demoPagesMenu, pageLayoutTypesPagesMenu } from "../menu";
import DefaultAside from "../pages/_layout/_asides/DefaultAside";

const asides = [
  { path: demoPagesMenu.login.path, element: null },
  { path: demoPagesMenu.landingPage.path, element: null },
  { path: demoPagesMenu.aboutUs.path, element: null },
  { path: demoPagesMenu.forgotPassword.path, element: null },
  { path: demoPagesMenu.register.path, element: null },
  { path: demoPagesMenu.order.path, element: null },
  { path: demoPagesMenu.phapLy.path, element: null },
  { path: demoPagesMenu.daily.path, element: null },
  { path: demoPagesMenu.chinhSach.path, element: null },
  { path: "*", element: <DefaultAside /> },
];

export default asides;
