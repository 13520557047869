import React, { useContext, useEffect, useState } from "react";
import UserLoginPage from "../UserLoginPage";
import styles from "../userLogin.module.css";
import { Link, useNavigate } from "react-router-dom";
import {
  MdOutlineMailOutline,
  MdPhone,
  MdLock,
  MdAddComment,
} from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { register } from "../../../../api/authApi";
import { useNotification } from "../../../../contexts/notificationContext";
import authContext from "../../../../contexts/authContext";
import ktv_logo_image from "../../../../assets/img/ktv-logo-login.png";
const Register = () => {
  const [registerForm, setRegisterForm] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
    phone: "",
    ref: "",
  });
  const { showNoti } = useNotification();
  const { setToken, setUserData } = useContext(authContext);
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    name: "",
    phone: "",
    ref_code: "",
  });
  const portrait = window.matchMedia("(orientation: portrait)").matches;
  const handleRegister = async () => {
    if (
      registerForm?.email === "" ||
      registerForm?.password === "" ||
      registerForm?.confirmPassword === "" ||
      registerForm?.name === "" ||
      registerForm?.phone === "" ||
      registerForm?.ref === ""
    ) {
      setErrMsg({
        email: registerForm.email === "" ? "Địa chỉ email là bắt buộc." : "",
        password: registerForm.password === "" ? "Mật khẩu là bắt buộc." : "",
        password_confirmation:
          registerForm.confirmPassword === "" ? "Mật khẩu là bắt buộc." : "",
        name: registerForm.name === "" ? "Tên người dùng là bắt buộc." : "",
        phone: registerForm.phone === "" ? "Số điện thoại là bắt buộc." : "",
        ref_code: registerForm.ref === "" ? "Mã giới thiệu là bắt buộc." : "",
      });
      return;
    }
    const input = {
      email: registerForm.email,
      password: registerForm.password,
      password_confirmation: registerForm.confirmPassword,
      name: registerForm.name,
      phone: registerForm.phone,
      ref_code: registerForm.ref,
    };
    try {
      const res = await register(input);
      if (res) {
        // setToken(res.data?.token);
        // localStorage.setItem("access_token", res.data?.token);
        localStorage.setItem("registerSuccess", "1");
        // setUserData(res.data?.user);
        showNoti("Thành công", "success");

        // navigate("/dang-nhap");
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        setErrMsg({
          email: "",
          password: "",
          password_confirmation: "",
          name: "",
          phone: "",
          ref_code: "",
          ...err?.response?.data?.error_msg,
        });
      } else showNoti(err?.response?.data?.error_msg, "error");
    }
  };

  const handleErrorMsg = (id) => {
    if (errMsg[id] !== "") {
      return <small className="text-danger m-0">{errMsg[id]}</small>;
    }
  };
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params?.ref_code) {
      setRegisterForm({ ...registerForm, ref: params?.ref_code });
    }
  }, [window.location]);
  return (
    <UserLoginPage>
      <div className={styles.login__Form}>
        {portrait ? (
          <div className="d-flex justify-content-center mb-5">
            <Link to={"/"}>
              <img src={ktv_logo_image} alt="" className={styles.logo} />
            </Link>
          </div>
        ) : (
          <></>
        )}
        <p className={styles.login__FormHeading}>Đăng ký</p>
        <p>Đăng ký để xem thông tin</p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleRegister();
          }}
        >
          <div>
            <div className={"formControl"}>
              <input
                value={registerForm?.name}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    name: e.target.value,
                  })
                }
                type="text"
                placeholder="Họ và tên"
                className={"formInput"}
              ></input>
              <FaUser className={"formIcon"} />
            </div>
            {handleErrorMsg("name")}
            <div className={"formControl"}>
              <input
                value={registerForm?.phone}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    phone: e.target.value,
                  })
                }
                type="text"
                placeholder="Số điện thoại"
                className={"formInput"}
              ></input>
              <MdPhone className={"formIcon"} />
            </div>
            {handleErrorMsg("phone")}
            <div className={"formControl"}>
              <input
                value={registerForm?.email}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    email: e.target.value,
                  })
                }
                type="text"
                placeholder="Email"
                className={"formInput"}
              ></input>
              <MdOutlineMailOutline className={"formIcon"} />
            </div>
            {handleErrorMsg("email")}
            <div className={"formControl"}>
              <input
                value={registerForm?.password}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    password: e.target.value,
                  })
                }
                type="password"
                placeholder="Mật khẩu"
                className={"formInput"}
              ></input>
              <MdLock className={"formIcon"} />
            </div>
            {handleErrorMsg("password")}
            <div className={"formControl"}>
              <input
                value={registerForm?.confirmPassword}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    confirmPassword: e.target.value,
                  })
                }
                type="password"
                placeholder="Nhập lại mật khẩu"
                className={"formInput"}
              ></input>
              <MdLock className={"formIcon"} />
            </div>
            {handleErrorMsg("password_confirmation")}
            <div className={"formControl"}>
              <input
                value={registerForm?.ref}
                onChange={(e) =>
                  setRegisterForm({
                    ...registerForm,
                    ref: e.target.value,
                  })
                }
                type="text"
                placeholder="Mã giới thiệu"
                className={"formInput"}
              ></input>
              <MdAddComment className={"formIcon"} />
            </div>
            {handleErrorMsg("ref_code")}
          </div>
          <button
            type="submit"
            className={"formSubmitButton"}
            // onClick={handleRegister}
          >
            Đăng ký
          </button>
        </form>
        <div className={styles.login__forgotPassword}>
          <Link to={"/dang-nhap"}>Quay lại đăng nhập</Link>
        </div>
      </div>
    </UserLoginPage>
  );
};

export default Register;
