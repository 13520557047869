import React from "react";
import { RouteProps } from "react-router-dom";
import { demoPagesMenu, pageLayoutTypesPagesMenu } from "../menu";
import LandingFooter from "../layout/LandingFooter/LandingFooter";
// import DefaultFooter from '../pages/_layout/_footers/DefaultFooter';

const footers = [
  { path: demoPagesMenu.landingPage.path, element: <LandingFooter /> },
  { path: demoPagesMenu.aboutUs.path, element: <LandingFooter /> },
  { path: demoPagesMenu.order.path, element: <LandingFooter /> },
  { path: demoPagesMenu.phapLy.path, element: <LandingFooter /> },
  { path: demoPagesMenu.chinhSach.path, element: <LandingFooter /> },
  { path: demoPagesMenu.daily.path, element: <LandingFooter /> },
];

export default footers;
