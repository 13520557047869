import React from "react";
import "../../../styles/cart.css";
import { IoClose } from "react-icons/io5";
import botdinhduong from "../../../assets/img/landing-page/product3.png";
import { Button } from "@mui/material";
import { priceFormat } from "../../../helpers/helpers";
import { useNavigate } from "react-router-dom";

const Cart = ({ open, setOpen, data, setData }) => {
  const navigate = useNavigate();

  return (
    <div className={`shoppingCart ${open ? "open" : "close"}`}>
      <div className="closeCartBody">
        <button onClick={() => setOpen(false)}>
          <IoClose />
        </button>
      </div>
      <div className="ShoppingCardBody">
        <div className="py-2 border-bottom">
          <h6 className="text-center">Tổng sản phẩm ({data})</h6>
        </div>
        <div>
          <div className="cardBody__item">
            <div style={{ width: "30%" }}>
              <img src={botdinhduong} alt="" className="cartItemImage" />
            </div>
            <div>
              <h6 className="m-0">Dinh dưỡng thiên nhiên KTV</h6>
              <p>Đơn giá: 3.300.000đ</p>
              <div>
                <div className="d-flex gap-2">
                  <button
                    className="cartButton"
                    onClick={() => {
                      if (data >= 2) {
                        setData((old) => --old);
                      }
                    }}
                  >
                    -
                  </button>
                  <input
                    style={{ width: "50px" }}
                    type="number"
                    value={data}
                    min={1}
                    max={100}
                    onChange={(e) => {
                      if (e.target.value < 1 || e.target.value.trim() === "") {
                        setData(1);
                      } else if (e.target.value > 100) {
                        setData(100);
                      } else {
                        setData(e.target.value);
                      }
                    }}
                  />
                  <button
                    className="cartButton"
                    onClick={() => setData((old) => ++old)}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cashout">
          <div>
            <span>Tổng cộng:</span>{" "}
            <span>
              <span className="text-danger fw-bold">
                {priceFormat(3300000 * data)}
              </span>
              <span className="text-dark fw-bold">
                {" ~ "}{" "}
                {(3300 * data).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}{" "}
                {"KTV"}
              </span>
            </span>
          </div>
          <Button
            onClick={() => {
              localStorage.setItem("orderQuantity", data);
              navigate("/mua-hang");
            }}
            className="buttonGreen fw-bold w-100 mt-3"
            variant="contained"
          >
            Thanh toán
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
