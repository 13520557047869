import React, { useState } from "react";
import UserLoginPage from "../UserLoginPage";
import styles from "../userLogin.module.css";
import { MdLock, MdOutlineMailOutline } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { forgotPassword } from "../../../../api/authApi";
import { useNotification } from "../../../../contexts/notificationContext";
import { sendPost } from "../../../../api/axiosClients";

const ForgotPassword = () => {
  const { showNoti } = useNotification();
  const location = useLocation();
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState({
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [forgotPasswordForm, setForgotPasswordForm] = useState({
    email: "",
    password: "",
    password_confirmation: "",
  });
  const code = location?.search?.substring(6);

  const handleForgotPassword = async () => {
    if (forgotPasswordForm?.email === "") {
      setErrMsg({ ...errMsg, email: "Chưa điền email" });
      return;
    }
    try {
      const input = {
        email: forgotPasswordForm?.email,
      };
      const res = await sendPost(`/user/auth/forgot-password`, input);
      if (res.success) {
        showNoti(res?.data, "success");
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 422) {
        setErrMsg({
          email: "",
          password: "",
          password_confirmation: "",
          ...error?.response?.data?.error_msg,
        });
      } else {
        showNoti(
          error?.response?.data?.error_msg
            ? error?.response?.data?.error_msg
            : error?.response?.data?.message,
          "error"
        );
      }
    }
  };

  const handleChangePassword = async () => {
    if (
      forgotPasswordForm?.password === "" ||
      forgotPasswordForm?.password_confirmation === ""
    ) {
      setErrMsg({
        ...errMsg,
        password:
          forgotPasswordForm?.password === "" ? "Chưa điền mật khẩu" : "",
        password_confirmation:
          forgotPasswordForm?.password_confirmation === ""
            ? "Chưa điền mật khẩu"
            : "",
      });
      return;
    }
    try {
      const input = {
        code: code,
        password: forgotPasswordForm?.password,
        password_confirmation: forgotPasswordForm?.password_confirmation,
      };
      const res = await sendPost(`/user/auth/reset-password`, input);
      if (res.success) {
        showNoti(res?.data, "success");
        navigate("/dang-nhap");
      }
    } catch (error) {
      if (error?.response?.status === 422) {
        setErrMsg({
          email: "",
          password: "",
          password_confirmation: "",
          ...error?.response?.data?.error_msg,
        });
      } else {
        showNoti(
          error?.response?.data?.error_msg
            ? error?.response?.data?.error_msg
            : error?.response?.data?.message,
          "error"
        );
      }
    }
  };

  const handleErrorMsg = (id) => {
    if (errMsg[id] !== "") {
      return <small className="text-danger">{errMsg[id]}</small>;
    }
  };

  return (
    <UserLoginPage>
      <div className={styles.login__Form}>
        <p className={styles.login__FormHeading}>
          {code?.length > 0 ? "Quên mật khẩu" : "Đổi mật khẩu"}
        </p>
        {/* <p>Chào mừng bạn quay lại!</p> */}
        <form onSubmit={(e) => {
          e.preventDefault()
          if(code?.length > 0){
            handleChangePassword()
          }else{
            handleForgotPassword()
          }
        }}>
          <div>
            {code?.length > 0 ? (
              <>
                <div className={"formControl"}>
                  <input
                    value={forgotPasswordForm?.password}
                    onChange={(e) =>
                      setForgotPasswordForm({
                        ...forgotPasswordForm,
                        password: e.target.value,
                      })
                    }
                    type="password"
                    placeholder="Mật khẩu"
                    className={"formInput"}
                  ></input>
                  <MdLock className={"formIcon"} />
                </div>
                {handleErrorMsg("password")}
                <div className={"formControl"}>
                  <input
                    value={forgotPasswordForm?.password_confirmation}
                    onChange={(e) =>
                      setForgotPasswordForm({
                        ...forgotPasswordForm,
                        password_confirmation: e.target.value,
                      })
                    }
                    type="password"
                    placeholder="Nhập lại mật khẩu"
                    className={"formInput"}
                  ></input>
                  <MdLock className={"formIcon"} />
                </div>
                {handleErrorMsg("password_confirmation")}
              </>
            ) : (
              <>
                <div className={"formControl"}>
                  <input
                    value={forgotPasswordForm?.email}
                    onChange={(e) =>
                      setForgotPasswordForm({
                        ...forgotPasswordForm,
                        email: e.target.value,
                      })
                    }
                    type="text"
                    placeholder="Email đăng ký"
                    className={"formInput"}
                  ></input>
                  <MdOutlineMailOutline className={"formIcon"} />
                </div>
                {handleErrorMsg("email")}
              </>
            )}
          </div>
          {code?.length > 0 ? (
            <button
              className={"formSubmitButton"}
              // onClick={handleChangePassword}
              type="submit"
            >
              Đổi mật khẩu
            </button>
          ) : (
            <button
              className={"formSubmitButton"}
              type="submit"
              // onClick={handleForgotPassword}
            >
              Gửi mã xác nhận
            </button>
          )}
        </form>

        <div className={styles.login__forgotPassword}>
          <Link to={"/dang-nhap"}>Quay lại đăng nhập</Link>
        </div>
      </div>
    </UserLoginPage>
  );
};

export default ForgotPassword;
