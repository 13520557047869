import React from "react";
import { Route, Router, Routes } from "react-router-dom";
import asides from "../../routes/asideRoutes";

const AsideRoutes = () => {
  return (
      <Routes>
        {asides.map((page) => (
          <Route key={page.path} path={page.path} element={page.element} />
        ))}
      </Routes>
  );
};

export default AsideRoutes;
