export const priceFormat = (price) => {
  return price?.toLocaleString("de-DE", {
    style: "currency",
    currency: "VND",
  });
};

export const numberFormat = (price) => {
  return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export function convertToInternationalFormat(phoneNumber) {
  // Loại bỏ các ký tự không phải số trong số điện thoại
  const cleanedNumber = phoneNumber.replace(/\D/g, "");

  // Kiểm tra xem số điện thoại có đủ 10 chữ số không
  if (cleanedNumber.length === 10) {
    // Nếu có đủ 10 chữ số, thêm mã quốc gia +84 vào đầu số
    return "+84" + cleanedNumber;
  } else {
    // Nếu không đủ 10 chữ số, trả về số điện thoại gốc
    return phoneNumber;
  }
}
export function getFormattedDate(month) {
  // Lấy ngày hiện tại
  let currentDate = new Date();

  // Lấy ngày 1 tháng trước
  let lastMonthDate = new Date(currentDate);
  lastMonthDate.setMonth(currentDate.getMonth() - month);

  // Định dạng ngày theo dd/m/yyyy
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Trả về kết quả
  return {
    currentDate: formatDate(currentDate),
    lastMonthDate: formatDate(lastMonthDate),
  };
}
