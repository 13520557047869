import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
// import "../styles/loading.css";
export const LoadingContext = createContext();
export const useLoading = () => useContext(LoadingContext);
const Loading = () => {
  return (
    <>
      <div id="pages_loading">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export const LoadingProvider = (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <LoadingContext.Provider
      value={{
        loading: loading,
        show: () => setLoading(true),
        hide: () => setLoading(false),
      }}
    >
      {loading && <Loading />}
      {props.children}
    </LoadingContext.Provider>
  );
};
LoadingProvider.propTypes = {
  children: PropTypes.node,
};
