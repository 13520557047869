import React, { useEffect } from "react";
import classNames from "classnames";
import HeaderRoutes from "../Header/HeaderRoutes";
import Content from "../Content/Content";
import FooterRoutes from "../Footer/FooterRoutes";
import "../../styles/main.css";
import { useLocation } from "react-router-dom";
import { useMedia } from "react-use";
import MobileMenu from "../../pages/_layout/_mobiMenu/MobileMenu";
import ScrollToTop from "../../Components/Common/ScrollToToop";
export const WrapperContainer = ({ children, className, ...props }) => {
  const location = useLocation();
  const isWide = useMedia("(min-width: 783px)");

  const handleWrapper = () => {
    switch (location.pathname) {
      case "/dang-nhap":
      case "/dang-ky":
      case "/quen-mat-khau":
      case "/dang-ky":
      case "/dang-ky-admin":
      case "/":
      case "/mua-hang":
      case "/phap-ly":
      case "/chinh-sach":
      case "/dai-ly":
      case "/ve-chung-toi":
        return false;
      default:
        return true;
    }
  };

  return (
    <div
      className={classNames(
        {
          wrapper: handleWrapper() && isWide,
          wrapper__mobile: handleWrapper() && !isWide,
        },
        // { 'wrapper-right-panel-active': rightPanel },
        className
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </div>
  );
};
const Wrapper = () => {
  const location = useLocation();
  const isWide = useMedia("(min-width: 783px)");
  const portrait = window.matchMedia("(orientation: portrait)").matches;
  const handleMobileMenu = () => {
    switch (location.pathname) {
      case "/":
      case "/mua-hang":
      case "/phap-ly":
      case "/chinh-sach":
      case "/dai-ly":
      case "/ve-chung-toi":
        return true;
      default:
        return false;
    }
  };

  useEffect(() => {
    localStorage.removeItem("registerSuccess");
  }, []);

  return (
    <>
      <WrapperContainer>
        <HeaderRoutes />
        <Content />
        <FooterRoutes />
        {(handleMobileMenu() && !isWide) || (handleMobileMenu() && portrait) ? (
          <MobileMenu />
        ) : (
          <></>
        )}
        <ScrollToTop />
      </WrapperContainer>
    </>
  );
};

export default Wrapper;
