import React from "react";
import { demoPagesMenu } from "../menu";
import CoreHeader from "../pages/_layout/_headers/CoreHeader";
import CustomHeader from "../pages/_layout/_headers/CustomHeader";
import LandingHeader from "../layout/LandingHeader/LandingHeader";

const headers = [
  { path: demoPagesMenu.loginAdmin.path, element: <CoreHeader /> },
  { path: demoPagesMenu.login.path, element: <CoreHeader /> },
  { path: demoPagesMenu.register.path, element: <CoreHeader /> },
  { path: demoPagesMenu.forgotPassword.path, element: <CoreHeader /> },
  { path: demoPagesMenu.landingPage.path, element: <LandingHeader /> },
  { path: demoPagesMenu.aboutUs.path, element: <LandingHeader /> },
  { path: demoPagesMenu.phapLy.path, element: <LandingHeader /> },
  { path: demoPagesMenu.chinhSach.path, element: <LandingHeader /> },
  { path: demoPagesMenu.daily.path, element: <LandingHeader /> },
  { path: demoPagesMenu.order.path, element: <LandingHeader /> },
  { path: "/*", element: <CustomHeader /> },
];

export default headers;
