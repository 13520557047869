import React, { useContext, useEffect, useState } from "react";
import styles from "./landingHeader.module.css";
import { Link, useNavigate } from "react-router-dom";
import logo_ktv from "../../assets/img/ktv-logo-login.png";
import authContext from "../../contexts/authContext";
import { useNotification } from "../../contexts/notificationContext";
import { getInfo } from "../../api/authApi";
import { Dropdown } from "react-bootstrap";
import { Button, Menu, MenuItem } from "@mui/material";
import defaultImage from "../../assets/img/default-user-image.svg";
import { FaUser, FaSignOutAlt } from "react-icons/fa";
import avatarImg from "../../assets/img/default-user-image.svg";
import { IoMdArrowDropdown } from "react-icons/io";
const LandingHeader = () => {
  const [openLogin, setOpenLogin] = useState(true);
  const portrait = window.matchMedia("(orientation: portrait)").matches;
  const { showNoti } = useNotification();
  const { userData, setUserData, setOpenMenuMobile } = useContext(authContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorE2);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };

  const getUserInfo = async () => {
    try {
      const res = await getInfo();
      if (res) {
        setUserData(res.data);
      }
    } catch (err) {
      // console.log(err);
      localStorage.removeItem("access_token");
    }
  };

  useEffect(() => {
    if (
      Object.keys(userData).length === 0 &&
      userData.constructor === Object &&
      localStorage.getItem("access_token")
    ) {
      getUserInfo();
    }
  }, []);

  const handleLogout = async () => {
    localStorage.removeItem("access_token");
    navigate("/dang-nhap");
  };

  return (
    <>
      {openLogin &&
      Object.keys(userData).length === 0 &&
      userData.constructor === Object &&
      !localStorage.getItem("access_token") ? (
        <div className={styles.landingPage__login}>
          <div></div>
          <Link to={"/dang-ky"}>
            <h4 className="m-0">Đăng ký ngay để mua hàng</h4>
          </Link>
          <div
            className={styles.closeLoginButton}
            onClick={() => setOpenLogin(false)}
          >
            x
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={styles.landingPage__navbar}>
        {portrait ? (
          <>
            <div>
              <Link to="/">
                <img src={logo_ktv} alt="" className={styles.navbar__logo} />
              </Link>
            </div>
            <div onClick={() => setOpenMenuMobile(true)}>
              {Object.keys(userData).length === 0 &&
              userData.constructor === Object &&
              !localStorage.getItem("access_token") ? (
                <img
                  src={avatarImg}
                  className={`me-2 ${styles.navbar__user}`}
                />
              ) : (
                <img
                  src={userData?.avatar ? userData?.avatar : avatarImg}
                  alt=""
                  className={`me-2 ${styles.navbar__user}`}
                />
              )}
            </div>
          </>
        ) : (
          <>
            <div className={styles.navbar__left}>
              <Link to={"/"}>TRANG CHỦ</Link>
              <Link to={"/ve-chung-toi"}>GIỚI THIỆU</Link>
              <Link to={"/phap-ly"}>PHÁP LÝ</Link>
            </div>
            <div>
              <Link to="/">
                <img src={logo_ktv} alt="" className={styles.navbar__logo} />
              </Link>
            </div>
            <div className={styles.navbar__right}>
              <Link to={"/chinh-sach"}>ĐIỀU KHOẢN</Link>
              <Link to={"/dai-ly"}>ĐẠI LÝ</Link>
              {(Object.keys(userData).length === 0 &&
                userData.constructor === Object) ||
              !localStorage.getItem("access_token") ? (
                <Link to={"/dang-nhap"}>ĐĂNG NHẬP/ĐĂNG KÝ</Link>
              ) : (
                <>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      className="d-flex align-items-center"
                    >
                      <img
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        }}
                        src={userData?.avatar ? userData?.avatar : defaultImage}
                        alt=""
                      />
                      <div className={styles.userName}>
                        {userData?.name ? userData?.name : "Username"}
                      </div>
                      <IoMdArrowDropdown className="text-dark ms-2" />
                    </div>
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      style={{
                        fontFamily: "Montserrat",
                      }}
                      onClick={() => navigate("/tong-quan")}
                    >
                      <FaUser className="me-2" /> Hệ thống quản lý
                    </MenuItem>
                    <MenuItem
                      style={{
                        fontFamily: "Montserrat",
                      }}
                      onClick={handleLogout}
                    >
                      <FaSignOutAlt className="me-2" /> Đăng xuất
                    </MenuItem>
                  </Menu>
                  {/* <Link to={"/dashboard"}>NGƯỜI DÙNG</Link> */}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LandingHeader;
