import { sendGet, sendPost } from "./axiosClients";

export const login = (data) => sendPost('/user/auth/login', data);
export const register = (data) => sendPost('/user/auth/register',data);
export const forgotPassword = (data) => sendPost('/user/auth/forgot-password', data);
export const resetPassword = (data) => sendPost('/user/auth/reset-password', data);
export const changePassword = (data) => sendPost('/user/auth/change-password', data);
export const getInfo = (data) => sendGet('/user/account', data);
export const changeInfo = (data) => sendPost('/user/account', data);
export const changeBank = (data) => sendPost('/user/account/bank', data);
export const verifyEmail = (data) => sendPost('/user/auth/verify-email', data);