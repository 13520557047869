import React, { useContext, useState } from "react";
import styles from "./mobileMenu.module.css";
import { FaHome, FaUser } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { HiMenu } from "react-icons/hi";
import { IoMdLogIn } from "react-icons/io";
import authContext from "../../../contexts/authContext";
import { Menu, MenuItem } from "@mui/material";
import MobileAsideMenu from "./mobile-aside-menu/MobileAsideMenu";
const MobileMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userData, setUserData, openMenuMobile, setOpenMenuMobile } =
    useContext(authContext);
  const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleOpenMenu = () => {
    setOpenMenuMobile(true);
  };

  const handleLogout = async () => {
    localStorage.removeItem("access_token");
    navigate("/dang-nhap");
  };

  return (
    <div className={styles.mobileMenu}>
      <MobileAsideMenu />
      <div
        className={`${styles.menuItem} ${
          location.pathname === "/" ? styles.selectedItem : ""
        }`}
        onClick={() => navigate("/")}
      >
        <div className="text-center">
          <FaHome />
        </div>
        <div className={styles.menuItem__name}>Trang chủ</div>
      </div>
      {/* <div className={styles.menuItem}>
        <div className="text-center">
          <HiMenu />
        </div>
        <div className={styles.menuItem__name}>Null</div>
      </div> */}
      {(Object.keys(userData).length === 0 &&
        userData.constructor === Object) ||
      !localStorage.getItem("access_token") ? (
        <></>
      ) : (
        <div className={styles.menuItem} onClick={() => navigate("/tong-quan")}>
          <div className="text-center">
            <FaUser />
          </div>
          <div className={styles.menuItem__name}>Hệ thống</div>
        </div>
      )}

      {/* <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          style={{
            fontFamily: "Montserrat",
          }}
          onClick={() => navigate("/ve-chung-toi")}
        >
          Giới thiệu
        </MenuItem>
        <MenuItem
          style={{
            fontFamily: "Montserrat",
          }}
          onClick={() => navigate("/phap-ly")}
        >
          Pháp lý
        </MenuItem>
        <MenuItem
          style={{
            fontFamily: "Montserrat",
          }}
          onClick={() => navigate("/chinh-sach")}
        >
          Chính sách
        </MenuItem>
        <MenuItem
          style={{
            fontFamily: "Montserrat",
          }}
          onClick={() => navigate("/dai-ly")}
        >
          Đại lý
        </MenuItem>
      </Menu> */}
      <div
        className={styles.menuItem}
        // aria-controls={open ? "basic-menu" : undefined}
        // aria-haspopup="true"
        // aria-expanded={open ? "true" : undefined}
        onClick={handleOpenMenu}
      >
        <div className="text-center">
          <HiMenu />
        </div>
        <div className={styles.menuItem__name}>Menu</div>
      </div>
      {localStorage?.getItem("access_token") ? (
        <div className={styles.menuItem} onClick={() => handleLogout()}>
          <div className="text-center">
            <IoMdLogIn />
          </div>
          <div className={styles.menuItem__name}>Đăng xuất</div>
        </div>
      ) : (
        <div className={styles.menuItem} onClick={() => navigate("/login")}>
          <div className="text-center">
            <IoMdLogIn />
          </div>
          <div className={styles.menuItem__name}>Đăng nhập</div>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
