import classNames from "classnames";
import React, { useContext, useState } from "react";
import { IoLogOutSharp } from "react-icons/io5";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LogoImg from "../../../assets/img/ktv-logo-login.png";
import authContext from "../../../contexts/authContext";
import { adminDashboardMenu, adminMenu } from "../../../menu";
import "../../../styles/aside.css";
import { useMedia } from "react-use";
import { IoCloseSharp } from "react-icons/io5";
const NavigationItem = ({ data, onSubmit }) => {
  const location = useLocation();
  const isWide = useMedia("(min-width: 769px)");
  return (
    <li
      onClick={() => {
        // onSubmit();
        // setShowSubmenu(false);
        // setShowSettingMenu(false);
      }}
      className="aside__body__list__item"
    >
      <NavLink
        to={data?.path}
        className={classNames(
          {
            item__router__active: location.pathname === data?.path,
          },
          "item__router"
        )}
      >
        <div className="item__links">
          {data?.icon}
          <div className="item__dashboard">{data?.text}</div>
        </div>
      </NavLink>
    </li>
  );
};
const NavigationCollapse = ({ data, onSubmit, id }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const location = useLocation();
  const isWide = useMedia("(min-width: 769px)");
  return (
    <li
      onClick={() => {
        setShowSubmenu(!showSubmenu);
        onSubmit(data?.id);
        // setShowSettingMenu(false);
      }}
      className={classNames("aside__body__list__item aside__item__collapse", {
        aside__item__collapse__active: isWide && showSubmenu && id == data?.id,
        aside__item__collapse__mobile__active:
          !isWide && showSubmenu && id == data?.id,
      })}
    >
      <div
        style={{ marginLeft: "10px", marginRight: "10px" }}
        className="w-100"
      >
        <div className="item__links d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            {data?.icon}
            <div className="item__dashboard">{data?.text}</div>
          </div>
          <div>
            {showSubmenu && id == data?.id ? (
              <MdKeyboardArrowUp />
            ) : (
              <MdKeyboardArrowDown />
            )}
          </div>
        </div>
        {showSubmenu && id == data?.id && (
          <ul
            className={classNames({
              sub__menu__list: isWide,
              sub__menu__list__mobile: !isWide,
            })}
          >
            {Object.keys(data?.subMenu)?.map((item) => (
              <NavigtaionCollapseItem key={item} data={data?.subMenu[item]} />
            ))}
          </ul>
        )}
      </div>
    </li>
  );
};
const NavigtaionCollapseItem = ({ data }) => {
  const location = useLocation();
  const isWide = useMedia("(min-width: 769px)");
  return (
    <li
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={classNames({
        sub__menu__list__item: isWide,
        sub__menu__list__item__mobile: !isWide,
      })}
    >
      <NavLink
        to={data?.path}
        className={classNames({
          item__router__active: location.pathname === data?.path,
        })}
      >
        {!isWide && <>{data?.icon}</>}
        <div className="item__links">
          {isWide && <>{data?.icon}</>}
          <div className="item__dashboard">{data?.text}</div>
        </div>
      </NavLink>
    </li>
  );
};
const DefaultAside = ({ to }) => {
  const { userData, asideStatus, setAsideStatus } = useContext(authContext);
  const navigate = useNavigate();
  const handleLogout = async () => {
    localStorage.removeItem("access_token");
    navigate("/dang-nhap");
  };
  const isWide = useMedia("(min-width: 769px)");

  const [id, setId] = useState("");
  return (
    <div
      className={classNames({
        aside: isWide,
        aside__mobile: !isWide,
        "aside__mobile aside__mobile__active":!isWide && asideStatus,
        "aside__mobile aside__mobile__hidden":!isWide &&  !asideStatus,
      })}
    >
      {!isWide && (
        <IoCloseSharp
          onClick={() => {
            setAsideStatus(false);
          }}
          className="close__aside__btn"
        />
      )}
      <div className="aside__head text-center d-flex align-items-center flex-column">
        <img
          src={LogoImg}
          alt=""
          className="aside__head__ava"
          onClick={() => navigate("/")}
        />
        <div
          className={classNames({
            aside__head__name: isWide,
            "d-none": !isWide,
          })}
        >
          KTV - Dinh dưỡng thiên nhiên
          {/* {!userData?.name ? "Username" : userData?.name} */}
        </div>
      </div>
      <div className="aside__body">
        <ul className="aside__body__list">
          {userData?.role_id == 1 ? (
            <>
              {Object.keys(adminMenu)?.map((item) => {
                if (!adminMenu[item].subMenu) {
                  return <NavigationItem key={item} data={adminMenu[item]} />;
                } else {
                  return (
                    <NavigationCollapse
                      key={item}
                      id={id}
                      onSubmit={(data) => {
                        setId(data);
                      }}
                      data={adminMenu[item]}
                    />
                  );
                }
              })}
            </>
          ) : (
            <>
              {Object.keys(adminDashboardMenu)?.map((item) => {
                if (!adminDashboardMenu[item].subMenu) {
                  return <NavigationItem key={item} data={adminDashboardMenu[item]} />;
                } else {
                  return (
                    <NavigationCollapse
                      id={id}
                      key={item}
                      onSubmit={(data) => {
                        setId(data);
                      }}
                      data={adminDashboardMenu[item]}
                    />
                  );
                }
              })}
            </>
          )}

          {/* <li onClick={handleLogout} className="aside__body__list__item">
            <div className="item__links logout item__router">
              <div className="d-flex">
                <IoLogOutSharp />
                {isWide && <div className="item__dashboard">Đăng xuất</div>}
              </div>
            </div>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default DefaultAside;
