import React, { useContext } from "react";
import styles from "./mobileAsideMenu.module.css";
import logo from "../../../../assets/img/ktv-logo-login.png";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import authContext from "../../../../contexts/authContext";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { MdOutlineAlternateEmail } from "react-icons/md";
const MobileAsideMenu = () => {
  const { userData, openMenuMobile, setOpenMenuMobile } =
    useContext(authContext);
  const navigate = useNavigate();
  const moreInfo = [
    {
      icon: <IoPhonePortraitOutline />,
      name: "Hotline",
      value: "039.686.5179",
    },
    {
      icon: <MdOutlineAlternateEmail />,
      name: "Email",
      value: "khoehanhphuc99@gmail.com",
    },
  ];

  return (
    <>
      {openMenuMobile ? (
        <div
          className={styles.menuMobileAsideLayer}
          onClick={() => setOpenMenuMobile(false)}
        ></div>
      ) : (
        <></>
      )}
      <div
        className={`${styles.mobileAside} ${
          openMenuMobile ? styles.open : styles.close
        }`}
      >
        <div className="d-flex justify-content-center py-2">
          <img src={logo} alt="" style={{ width: "30%", aspectRatio: "1/1" }} />
        </div>
        <div className={styles.mobileAsideBody}>
          <div>
            {(Object.keys(userData).length === 0 &&
              userData.constructor === Object) ||
            !localStorage.getItem("access_token")?.length ? (
              <Link to={"/dang-nhap"}>
                <Button
                  variant="contained"
                  className="buttonGreen fw-bold w-100 mt-2"
                >
                  Đăng nhập
                </Button>
              </Link>
            ) : (
              <>
                <Link to={"/tong-quan"}>
                  <Button
                    variant="contained"
                    className="buttonGreen fw-bold w-100"
                  >
                    Hồ sơ của tôi
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  className="buttonGreen fw-bold w-100 mt-2"
                  onClick={() => {
                    localStorage.removeItem("access_token");
                    navigate("/dang-nhap");
                  }}
                >
                  Đăng xuất
                </Button>
              </>
            )}
          </div>
          <ul className="mt-5 pb-5 border-bottom">
            <li>
              <Link to="/ve-chung-toi">Về chúng tôi</Link>
            </li>
            <li>
              <Link to="/phap-ly">Pháp lý</Link>
            </li>
            <li>
              <Link to="/chinh-sach">Chính sách</Link>
            </li>
            <li>
              <Link to="/dai-ly">Đại lý</Link>
            </li>
          </ul>
          <div className="border-bottom pb-5">
            {moreInfo?.map((item, idx) => (
              <div className="d-flex align-items-center gap-2 mt-2">
                <div
                  className="col-2 text-center fs-1"
                  style={{ color: "#119744" }}
                >
                  {item?.icon}
                </div>
                <div className="fw-bold">
                  <p className="m-0">{item?.name}</p>
                  <p className="m-0 text-primary">
                    <small>{item?.value}</small>
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center mt-5">
            <small>
              Bản quyền thuộc về Công ty Cổ Phần Tập Đoàn Khỏe Đẹp Hạnh Phúc
            </small>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileAsideMenu;
