import React, { useContext, useState } from "react";
import "../../../styles/loginpage.css";
import { IoMdLogIn } from "react-icons/io";
import { useFormik } from "formik";
import { login, register } from "../../../api/authApi";
import { useNotification } from "../../../contexts/notificationContext";
import { useNavigate } from "react-router-dom";
import authContext from "../../../contexts/authContext";
const LoginPage = () => {
  const [signUpState, setSignUpState] = useState(false);
  const { showNoti } = useNotification();
  const { setToken, setUserData } = useContext(authContext);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      signupEmail: "",
      signupPassword: "",
      signupName: "",
      signupPhone: "",
      signupConfirmpassword: "",
      signupRef: "",
    },
  });
  const handleSubmit = async () => {
    if (signUpState) {
      const input = {
        email: formik.values.signupEmail,
        password: formik.values.signupPassword,
        password_confirmation: formik.values.signupConfirmpassword,
        name: formik.values.signupName,
        phone: formik.values.signupPhone,
        ref_code: formik.values.signupRef,
      };
      try {
        const res = await register(input);
        if (res) {
          showNoti("Thành công", "success");
          setSignUpState(false);
        }
      } catch (err) {
        showNoti(err?.response?.data?.error_msg, "error");
      }
    } else {
      const input = {
        email: formik.values.email,
        password: formik.values.password,
      };
      try {
        const res = await login(input);
        if (res) {
          showNoti("Thành công", "success");
          setToken(res?.data?.token);
          setUserData(res?.data?.user);
          navigate("/");
        }
      } catch (err) {
        showNoti(err?.response?.data?.error_msg, "error");
      }
    }
  };
  return (
    <>
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <div className="login100-form validate-form">
              <span className="login100-form-logo">
                <IoMdLogIn className="zmdi zmdi-landscape" />
              </span>
              <span className="login100-form-title pb-2 pt-2">
                {signUpState ? "Đăng ký" : "Đăng nhập"}
              </span>
              {signUpState ? (
                <>
                  <div className="wrap-input100 validate-input">
                    <input
                      value={formik.values.signupEmail}
                      id="signupEmail"
                      onChange={formik.handleChange}
                      className="input100"
                      type="text"
                      placeholder="Email"
                    />
                  </div>
                  <div className="wrap-input100 validate-input">
                    <input
                      value={formik.values.signupPassword}
                      id="signupPassword"
                      onChange={formik.handleChange}
                      className="input100"
                      type="password"
                      placeholder="Mật khẩu"
                    />
                  </div>
                  <div
                    className="wrap-input100 validate-input"
                    data-validate="Enter username"
                  >
                    <input
                      className="input100"
                      type="password"
                      value={formik.values.signupConfirmpassword}
                      id="signupConfirmpassword"
                      onChange={formik.handleChange}
                      placeholder="Nhập lại mật khẩu"
                    />
                  </div>
                  <div
                    className="wrap-input100 validate-input"
                    data-validate="Enter password"
                  >
                    <input
                      className="input100"
                      type="text"
                      value={formik.values.signupName}
                      id="signupName"
                      onChange={formik.handleChange}
                      placeholder="Họ và tên"
                    />
                  </div>
                  <div
                    className="wrap-input100 validate-input"
                    data-validate="Enter username"
                  >
                    <input
                      className="input100"
                      type="text"
                      value={formik.values.signupPhone}
                      id="signupPhone"
                      onChange={formik.handleChange}
                      placeholder="Số điện thoại"
                    />
                  </div>
                  <div
                    className="wrap-input100 validate-input"
                    data-validate="Enter password"
                  >
                    <input
                      className="input100"
                      type="password"
                      value={formik.values.signupRef}
                      id="signupRef"
                      onChange={formik.handleChange}
                      placeholder="Mã giới thiệu"
                    />
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <div
                    className="wrap-input100 validate-input"
                    data-validate="Enter username"
                  >
                    <input
                      value={formik.values.email}
                      id="email"
                      onChange={formik.handleChange}
                      className="input100"
                      type="text"
                      placeholder="Tài khoản"
                    />
                  </div>
                  <div
                    className="wrap-input100 validate-input"
                    data-validate="Enter password"
                  >
                    <input
                      id="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      className="input100"
                      type="password"
                      placeholder="Mật khẩu"
                    />
                  </div>
                </>
              )}
              <div className="container-login100-form-btn">
                <button onClick={handleSubmit} className="login100-form-btn">
                  {signUpState ? "Đăng ký" : "Đăng nhập"}
                </button>
              </div>
              <div className="d-flex justify-content-around mt-2">
                <div className="txt1" href="#">
                  Quên mật khẩu?
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSignUpState(!signUpState);
                  }}
                  className="txt1"
                >
                  {signUpState ? "Đăng nhập" : "Đăng ký"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
